$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (skills-1, 70px, 70px, -30px 0),
  (skills-2, 70px, 70px, -100px 0),
  (skills-3, 70px, 70px, -170px 0),
  (skills-4, 70px, 70px, -240px 0),
  (skills-5, 70px, 70px, -310px 0),  
  (search, 60px, 60px, -380px 0),  
  (arrow-regular-left, 60px, 60px, -30px -70px),  
  (arrow-regular-right, 60px, 60px, -90px -70px),  
  (arrow-alt-left, 60px, 60px, -150px -70px),  
  (arrow-alt-right, 60px, 60px, -210px -70px),  
  (arrow-small-left, 10px, 20px, -280px -80px),  
  (arrow-small-right, 10px, 20px, -270px -80px),  
  (facebook-white, 30px, 30px, -290px -70px),  
  (instagram-white, 30px, 30px, -320px -70px),  
  (location-white, 30px, 30px, -350px -70px),  
  (facebook-black, 30px, 30px, -290px -100px),  
  (instagram-black, 30px, 30px, -320px -100px),  
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
      @if $label == facebook-black or $label == instagram-black or $label == facebook-white or $label == instagram-white {
        @include transition(all, 0.1s, ease-in-out);
        &:hover {
          transform: translateY(-5px);
        }
      }
      @if $label == arrow-regular-left or $label == arrow-regular-right or $label == arrow-alt-left or $label == arrow-alt-right or $label == arrow-small-left or $label == arrow-small-right {
        @include transition(all, 0.1s, ease-in-out);
        &:hover {
          transform: scale(.9);
        }
      }

    }
  }
}