$colors: (
  'black': #000,
  'white': #FFFFFF,
  'grey-dark': #828282,
  'grey-light': #F4F4F4,
  'error': #a94442,
  'blue-xdark': #1A25F1,
  'blue-dark': #15a1df,
  'blue-regular': #2fb6ee,
  'blue-light': #4fccff,
  'blue-xlight': #59D2FF,
  'green-dark': #0fdf99,
  'green-regular': #20FC90,
  'green-light': #73FBD3,
  'orange': #FE7F2E,
  'purple': #BB1866
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}