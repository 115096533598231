.form-control {
	color: $black!important;

  &::placeholder {
    color: lighten($black, 50%);
  }
}

.label {
	color: color.scale($black, $lightness: 35%);
	font-family: $font-regular;
	margin-bottom: 5px;
}

.select2-container {
	text-align: left;
	min-width: 200px;

	&.select2-container--default {
		.select2-selection--single {
			height: 47.5px;
			border-color: $gray-400;

			.select2-selection {
				&__placeholder {
					opacity: 1;
					color: lighten($black, 50%);
				}
				
				&__rendered {
					padding-left: 14px;
					line-height: 3.4em;
					color: map.get($colors, 'dark');
				}
				
				&__arrow {
					top: 12px;
					right: 5px;
				}
			}
			
		}
	}

	.select2-results__option--highlighted {
		&.select2-results__option--highlighted {
			background-color: map.get($colors, 'blue-xdark');
		}
	}
}

.file-picker {
  display: inline-block;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
	position: relative;
	width: 100%;

	&--input {
    height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
		position: relative;
		width: 100%;
		z-index: 2;
	}

	&--label {
    height: calc(2.25rem + 2px);
    left: 0;
		position: absolute;
    top: 0;
    right: 0;
		z-index: 1;
		@extend .form-control;

		&::after {
			align-items: center;
			background-color: $black;
			bottom: 0;
			color: $white;
			display: flex;
			justify-content: center;
			height: calc(calc(2.25rem + 2px) - 1px * 2);
			padding: 0 15px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
		}
	}

	&--input:lang(fr) ~ .file-picker--label {
    &::after {
      content: "Rechercher";
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-state-default.ui-state-active.ui-state-hover {
	border: none;
	background: map.get($colors, 'black') !important;
}