$categories: (
  'computers' : 'category-computers.png',
  'components' : 'category-components.png',
  'connectors' : 'category-connectors.png',
  'devices' : 'category-devices.png',
  'networks' : 'category-networks.png',
  'consumables' : 'category-consumables.png',
);

.home-category-entry {
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  color: $white;
  height: 700px;
  position: relative;
  text-align: center;
  width: 100%;

  &:after {
    content: "";
    background-color: color.adjust($black, $alpha: -0.5);
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    @include transition(all, 0.1s, ease-in-out);
  }
  
  @each $name, $background in $categories {
    &.type-#{$name} {
      background-image: url('assets/images/#{$background}');
    }
  }

  &--menu-wrapper {
    display: none;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    padding: 40px 30px;
    width: 100%;
    z-index: 2;
    @include transition(all, 0.1s, ease-in-out);
  
    &--item {
      display: block;
      margin: 15px 0;
    }
    
    &--link {
      font-family: $font-bold;
      text-decoration: underline;
      @include font-size(22);
      @include transition(all, 0.1s, ease-in-out);

      &:hover {
        color: map.get($colors, 'green-regular');
      }

      @include media-breakpoint-down(sm) {
        @include font-size(18);
      }
    }
  }
  
  &:hover {
    .title-wrapper {
      display: none;
    }

    .home-category-entry--menu-wrapper {
      display: block;
    }

    &:after {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(xxl) {
    height: 600px;
  }

  @include media-breakpoint-down(xl) {
    height: 500px;
  }
}