@use "sass:color";
@use "sass:math";
@use "sass:map";

@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';
@import 'bootstrap/variables-bootstrap';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import 'bootstrap/variables-bootstrap-utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/containers';
//@import '~bootstrap/scss/close';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/list-group';
//@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/reboot';
//@import '~bootstrap/scss/spinners';
//@import '~bootstrap/scss/popover';
//@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/type';
//@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/nav';

@import 'mixins/spacing-generator';
@import 'mixins/font-size';
@import 'mixins/triangle';
@import 'mixins/transition';

@import 'helpers/fonts';
@import 'helpers/colors';
@import 'helpers/buttons';
@import 'helpers/form';
@import 'helpers/print';
@import 'helpers/sprite';
@import 'helpers/spacing';

@import 'layout/reboot';
@import 'layout/header';
@import 'layout/footer';

@import 'components/aos';
//@import 'components/google-map';
//@import 'components/jquery.parallax';
//@import 'components/jquery-ui';
@import 'components/ion-range-slider';
//@import 'components/magnific-popup';
@import 'components/parsleyjs';
@import 'components/slick';

@import 'pages/commons';
@import 'pages/brands';
@import 'pages/contact';
@import 'pages/home';
@import 'pages/products';
@import 'pages/faq';
@import 'pages/wysiwyg';


