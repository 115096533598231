#footer {
	background-color: map.get($colors, 'black');
	color: $white;

	.footer-navigation {
		display: block;
		@include transition(all, 0.1s, ease-in-out);

		&:hover {
			color: $white;
			transform: translateX(5px);
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}