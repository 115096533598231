.irs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  position: relative;
}

.irs-line {
  display: block;
  position: relative;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  width: 0;
  display: block;
  position: absolute;
  left: 0;
}

.irs-shadow {
  width: 0;
  display: none;
  position: absolute;
  left: 0;
}

.irs-handle {
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
  display: block;
  position: absolute;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min, .irs-max {
  cursor: default;
  display: block;
  position: absolute;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from, .irs-to, .irs-single {
  cursor: default;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid {
  width: 100%;
  height: 20px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  width: 1px;
  height: 8px;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  white-space: nowrap;
  text-align: center;
  color: #000;
  padding: 0 3px;
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-disable-mask {
  width: 102%;
  height: 100%;
  cursor: default;
  z-index: 2;
  background: none;
  display: block;
  position: absolute;
  top: 0;
  left: -1%;
}

.lt-ie9 .irs-disable-mask {
  filter: alpha(opacity= 0);
  cursor: not-allowed;
  background: #000;
}

.irs-disabled {
  opacity: .4;
}

.irs-hidden-input {
  overflow: hidden;
  width: 0 !important;
  height: 0 !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: #0000 !important;
  outline: none !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
  top: 25px;
}

.irs--flat .irs-bar {
  height: 12px;
  background-color: #ed5565;
  top: 25px;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  background-color: #e1e4e9;
  bottom: 16px;
}

.irs--flat .irs-handle {
  width: 16px;
  height: 18px;
  background-color: #0000;
  top: 22px;
}

.irs--flat .irs-handle > i:first-child {
  width: 2px;
  height: 100%;
  background-color: #da4453;
  margin-left: -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  color: #999;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
  padding: 1px 3px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  color: #fff;
  text-shadow: none;
  background-color: #ed5565;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #ed5565;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  height: 12px;
  background: linear-gradient(#ddd -50%, #fff 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
  top: 33px;
}

.irs--big .irs-bar {
  height: 12px;
  background: linear-gradient(#fff 0%, #428bca 30%, #b9d4ec 100%);
  border: 1px solid #428bca;
  top: 33px;
  box-shadow: inset 0 0 1px 1px #ffffff80;
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  background-color: #428bca80;
  bottom: 16px;
}

.irs--big .irs-handle {
  width: 30px;
  height: 30px;
  background: linear-gradient(#fff 0%, #b4b9be 30%, #fff 100%);
  border: 1px solid #0000004d;
  border-radius: 30px;
  top: 25px;
  box-shadow: 1px 1px 2px #0003, inset 0 0 3px 1px #fff;
}

.irs--big .irs-handle.state_hover, .irs--big .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #00000073;
}

.irs--big .irs-min, .irs--big .irs-max {
  color: #fff;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
  padding: 1px 5px;
  top: 0;
}

.irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single {
  color: #fff;
  text-shadow: none;
  background: linear-gradient(#428bca 0%, #3071a9 100%);
  border-radius: 3px;
  padding: 1px 5px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern, .irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  height: 5px;
  background: linear-gradient(#e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
  top: 25px;
}

.irs--modern .irs-bar {
  height: 5px;
  background: linear-gradient(#20b426 0%, #18891d 100%);
  top: 25px;
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  background-color: #d1d6e080;
  bottom: 21px;
}

.irs--modern .irs-handle {
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
  top: 37px;
  box-shadow: 1px 1px 1px #0000001a;
}

.irs--modern .irs-handle > i:first-child {
  width: 6px;
  height: 6px;
  background: #fff;
  border: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  box-sizing: border-box;
  width: 10px;
  height: 12px;
  background: linear-gradient(#fff 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs--modern .irs-handle > i:nth-child(3) {
  box-sizing: border-box;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
}

.irs--modern .irs-handle.state_hover, .irs--modern .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:first-child, .irs--modern .irs-handle:hover > i:first-child {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3), .irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min, .irs--modern .irs-max {
  text-shadow: none;
  color: #fff;
  background-color: #d1d6e0;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #20b426;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--modern .irs-from:before, .irs--modern .irs-to:before, .irs--modern .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #20b426;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  height: 2px;
  background-color: #000;
  border-radius: 2px;
  top: 30px;
}

.irs--sharp .irs-bar {
  height: 2px;
  background-color: #ee22fa;
  top: 30px;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  background-color: #00000080;
  bottom: 21px;
}

.irs--sharp .irs-handle {
  width: 10px;
  height: 10px;
  background-color: #a804b2;
  top: 25px;
}

.irs--sharp .irs-handle > i:first-child {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top-color: #a804b2;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.irs--sharp .irs-handle.state_hover, .irs--sharp .irs-handle:hover {
  background-color: #000;
}

.irs--sharp .irs-handle.state_hover > i:first-child, .irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: #000;
}

.irs--sharp .irs-min, .irs--sharp .irs-max {
  color: #fff;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--sharp .irs-from, .irs--sharp .irs-to, .irs--sharp .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
}

.irs--sharp .irs-from:before, .irs--sharp .irs-to:before, .irs--sharp .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #a804b2;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
  top: 36px;
}

.irs--round .irs-bar {
  height: 4px;
  background-color: #006cfa;
  top: 36px;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  background-color: #dee4ec80;
  bottom: 21px;
}

.irs--round .irs-handle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 4px solid #006cfa;
  border-radius: 24px;
  top: 26px;
  box-shadow: 0 1px 3px #0000ff4d;
}

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min, .irs--round .irs-max {
  color: #333;
  background-color: #0000001a;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #006cfa;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #006cfa;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  height: 4px;
  background-color: #dedede;
  top: 31px;
}

.irs--square .irs-bar {
  height: 4px;
  background-color: #000;
  top: 31px;
}

.irs--square .irs-shadow {
  height: 2px;
  background-color: #dedede;
  bottom: 21px;
}

.irs--square .irs-handle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 3px solid #000;
  top: 25px;
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover, .irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min, .irs--square .irs-max {
  color: #333;
  background-color: #0000001a;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--square .irs-from, .irs--square .irs-to, .irs--square .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #000;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid #0000;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.select2-container {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  min-height: 32px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  list-style: none;
  display: inline;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  word-break: keep-all;
  border: none;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  overflow: hidden;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  width: 100%;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  -webkit-user-select: none;
  user-select: none;
  padding: 6px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  box-sizing: border-box;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #333;
  background-color: #f1f1f1;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-radius: 0 4px 4px 0;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: linear-gradient(#fff 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  font-weight: bold;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: linear-gradient(#eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: linear-gradient(#fff 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: linear-gradient(#eee 50%, #fff 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-radius: 0 4px 4px 0;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid #0000;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}

.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #343a40;
  --bs-link-hover-color: #2a2e33;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 2.2rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1a25f1;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1a25f1;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.row {
  --bs-gutter-x: 2.2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control, .file-picker--label {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control, .file-picker--label {
    transition: none;
  }
}

.form-control[type="file"], [type="file"].file-picker--label {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]), [type="file"].file-picker--label:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus, .file-picker--label:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8d92f8;
  outline: 0;
  box-shadow: 0 0 0 .25rem #1a25f140;
}

.form-control::-webkit-date-and-time-value, .file-picker--label::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder, .file-picker--label::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .file-picker--label:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::file-selector-button, .file-picker--label::file-selector-button {
  margin: -1rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: 1rem;
  padding: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .file-picker--label::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .file-picker--label:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: 1rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control, textarea.file-picker--label {
  min-height: calc(1.5em + 2rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 2rem + 2px);
  padding: 1rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(1rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8d92f8;
  outline: 0;
  box-shadow: 0 0 0 .25rem #1a25f140;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.7em;
  margin-bottom: .125rem;
  padding-left: 2.7em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -2.7em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 2.7em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -2.7em;
}

.form-check-input {
  width: 1.7em;
  height: 1.7em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: -.1em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8d92f8;
  outline: 0;
  box-shadow: 0 0 0 .25rem #1a25f140;
}

.form-check-input:checked {
  background-color: #1a25f1;
  border-color: #1a25f1;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #1a25f1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #1a25f1;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238d92f8'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #1a25f140;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #1a25f140;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #1a25f1;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #babefb;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #1a25f1;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #babefb;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .file-picker--label, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .file-picker--label, .form-floating > .form-control-plaintext {
  padding: 1rem;
}

.form-floating > .form-control::placeholder, .form-floating > .file-picker--label::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .file-picker--label:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .file-picker--label:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .file-picker--label:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .file-picker--label:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .file-picker--label:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:autofill ~ label, .form-floating > .file-picker--label:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .file-picker--label, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .file-picker--label:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .file-picker--label, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .file-picker--label, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .file-picker--label, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .file-picker--label, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .file-picker--label, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .file-picker--label:valid, .form-control.is-valid, .is-valid.file-picker--label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .5rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + 1rem) calc(.75em + 1rem);
  border-color: #198754;
  padding-right: calc(1.5em + 2rem);
}

.was-validated .form-control:valid:focus, .was-validated .file-picker--label:valid:focus, .form-control.is-valid:focus, .is-valid.file-picker--label:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, .was-validated textarea.file-picker--label:valid, textarea.form-control.is-valid, textarea.is-valid.file-picker--label {
  background-position: right calc(.375em + .5rem) top calc(.375em + .5rem);
  padding-right: calc(1.5em + 2rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.75em + 1rem) calc(.75em + 1rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 5rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .file-picker--label:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > .file-picker--label:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .file-picker--label:invalid, .form-control.is-invalid, .is-invalid.file-picker--label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .5rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + 1rem) calc(.75em + 1rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + 2rem);
}

.was-validated .form-control:invalid:focus, .was-validated .file-picker--label:invalid:focus, .form-control.is-invalid:focus, .is-invalid.file-picker--label:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, .was-validated textarea.file-picker--label:invalid, textarea.form-control.is-invalid, textarea.is-invalid.file-picker--label {
  background-position: right calc(.375em + .5rem) top calc(.375em + .5rem);
  padding-right: calc(1.5em + 2rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.75em + 1rem) calc(.75em + 1rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 5rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .file-picker--label:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .file-picker--label:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #1a25f1;
  --bs-list-group-active-border-color: #1a25f1;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #1a25f1;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #1a25f140;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #1a25f1;
  --bs-pagination-active-border-color: #1a25f1;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-size: calc(1.265rem + .18vw);
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

@media (width >= 1200px) {
  .nav {
    --bs-nav-link-font-size: 1.4rem;
  }
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #f4f4f4;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #f4f4f4;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #1a25f1;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url("MaterialIcons-Regular.12b3b105.woff") format("woff");
}

@font-face {
  font-family: interbold;
  src: url("inter-bold-webfont.6f9e72f0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: interlight;
  src: url("inter-light-webfont.c70e2844.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: intermedium;
  src: url("inter-medium-webfont.97272b66.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

html {
  color: #000;
  font-size: 13px !important;
}

body {
  font-family: interlight, sans-serif;
}

.title-1, .wysiwyg-wrapper h1, .wysiwyg-wrapper .h1 {
  font-family: interbold, sans-serif;
  font-size: 3.84615rem;
  font-style: normal;
  line-height: 1.2;
}

@media (width <= 767.98px) {
  .title-1, .wysiwyg-wrapper h1, .wysiwyg-wrapper .h1 {
    font-size: 3.4965rem;
  }
}

.title-2, .wysiwyg-wrapper h2, .wysiwyg-wrapper .h2 {
  font-family: interbold, sans-serif;
  font-size: 2.30769rem;
  font-style: normal;
  line-height: 1.2;
}

@media (width <= 767.98px) {
  .title-2, .wysiwyg-wrapper h2, .wysiwyg-wrapper .h2 {
    font-size: 2.0979rem;
  }
}

.title-3, .wysiwyg-wrapper h3, .wysiwyg-wrapper .h3 {
  font-family: interbold, sans-serif;
  font-size: 2.15385rem;
  font-style: normal;
  line-height: 1.2;
}

@media (width <= 767.98px) {
  .title-3, .wysiwyg-wrapper h3, .wysiwyg-wrapper .h3 {
    font-size: 1.95804rem;
  }
}

.title-4 {
  font-family: interbold, sans-serif;
  font-size: 1.53846rem;
  font-style: normal;
  line-height: 1.2;
}

@media (width <= 767.98px) {
  .title-4 {
    font-size: 1.3986rem;
  }
}

.title-5 {
  font-family: interbold, sans-serif;
  font-size: 1.30769rem;
  font-style: normal;
  line-height: 1.2;
}

@media (width <= 767.98px) {
  .title-5 {
    font-size: 1.18881rem;
  }
}

.text-1, .wysiwyg-wrapper ol li, .wysiwyg-wrapper ul li, .wysiwyg-wrapper .highlight-wrapper, .wysiwyg-wrapper p {
  font-family: interlight, sans-serif;
  font-size: 1.53846rem;
  font-style: normal;
  line-height: 1.3;
}

.text-2, .wysiwyg-wrapper ol li:before {
  font-family: interbold, sans-serif;
  font-size: 1.07692rem;
  font-style: normal;
  line-height: 1.2;
}

.text-3 {
  font-family: intermedium, sans-serif;
  font-size: 1.07692rem;
  font-style: normal;
  line-height: 1.2;
}

.text-4 {
  font-family: interlight, sans-serif;
  font-size: 1.38462rem;
  font-style: normal;
  line-height: 1.3;
}

.text-5 {
  font-family: interlight, sans-serif;
  font-size: 1.15385rem;
  font-style: normal;
  line-height: 1.4;
}

.text-6 {
  font-family: interlight, sans-serif;
  font-size: 1.23077rem;
  font-style: normal;
  line-height: 1.3;
}

.color-black {
  color: #000;
}

.background-black {
  background-color: #000;
}

.color-white {
  color: #fff;
}

.background-white {
  background-color: #fff;
}

.color-grey-dark {
  color: #828282;
}

.background-grey-dark {
  background-color: #828282;
}

.color-grey-light {
  color: #f4f4f4;
}

.background-grey-light {
  background-color: #f4f4f4;
}

.color-error {
  color: #a94442;
}

.background-error {
  background-color: #a94442;
}

.color-blue-xdark {
  color: #1a25f1;
}

.background-blue-xdark {
  background-color: #1a25f1;
}

.color-blue-dark {
  color: #15a1df;
}

.background-blue-dark {
  background-color: #15a1df;
}

.color-blue-regular {
  color: #2fb6ee;
}

.background-blue-regular {
  background-color: #2fb6ee;
}

.color-blue-light {
  color: #4fccff;
}

.background-blue-light {
  background-color: #4fccff;
}

.color-blue-xlight {
  color: #59d2ff;
}

.background-blue-xlight {
  background-color: #59d2ff;
}

.color-green-dark {
  color: #0fdf99;
}

.background-green-dark {
  background-color: #0fdf99;
}

.color-green-regular {
  color: #20fc90;
}

.background-green-regular {
  background-color: #20fc90;
}

.color-green-light {
  color: #73fbd3;
}

.background-green-light {
  background-color: #73fbd3;
}

.color-orange {
  color: #fe7f2e;
}

.background-orange {
  background-color: #fe7f2e;
}

.color-purple {
  color: #bb1866;
}

.background-purple {
  background-color: #bb1866;
}

.button-regular {
  color: #000;
  text-align: center;
  text-transform: none;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50px;
  padding: 11px 35px;
  font-family: interbold, sans-serif;
  font-size: 1.07692rem;
  line-height: 1.2;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.button-regular:hover, .button-regular:focus {
  color: #fff;
  background-color: #000;
  border: 2px solid #000;
}

.button-regular-alt {
  color: #fff;
  text-align: center;
  text-transform: none;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 50px;
  padding: 11px 35px;
  font-family: interbold, sans-serif;
  font-size: 1.07692rem;
  line-height: 1.2;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.button-regular-alt:hover, .button-regular-alt:focus {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
}

.button-blue, .wysiwyg-wrapper .button-wrapper a {
  color: #fff;
  text-align: center;
  text-transform: none;
  background-color: #1a25f1;
  border: 2px solid #1a25f1;
  border-radius: 50px;
  padding: 11px 35px;
  font-family: interbold, sans-serif;
  font-size: 1.07692rem;
  line-height: 1.2;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.button-blue:hover, .wysiwyg-wrapper .button-wrapper a:hover, .button-blue:focus, .wysiwyg-wrapper .button-wrapper a:focus {
  color: #1a25f1;
  background-color: #fff;
  border: 2px solid #1a25f1;
}

.button--mobile {
  cursor: pointer;
  height: 20px;
  width: 30px;
  z-index: 2;
  margin: 0 auto;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
  transform: rotate(0);
}

.button--mobile--text {
  text-transform: uppercase;
  font-size: .692308rem;
  position: relative;
  top: 3px;
}

.button--mobile span {
  height: 3px;
  opacity: 1;
  width: 100%;
  background: #000;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  transform: rotate(0);
}

.button--mobile span:first-child {
  top: 0;
}

.button--mobile span:nth-child(2), .button--mobile span:nth-child(3) {
  top: 9px;
}

.button--mobile span:nth-child(4) {
  top: 18px;
}

.button--scroll-top {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

@media (width <= 575.98px) {
  .button--scroll-top {
    bottom: 15px;
    right: 15px;
  }
}

.menu-open .button--mobile span:first-child {
  width: 0;
  top: 18px;
  left: 50%;
}

.menu-open .button--mobile span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-open .button--mobile span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-open .button--mobile span:nth-child(4) {
  width: 0;
  top: 18px;
  left: 50%;
}

.form-control, .file-picker--label {
  color: #000 !important;
}

.form-control::placeholder, .file-picker--label::placeholder {
  color: gray;
}

.label {
  color: #595959;
  margin-bottom: 5px;
  font-family: intermedium, sans-serif;
}

.select2-container {
  text-align: left;
  min-width: 200px;
}

.select2-container.select2-container--default .select2-selection--single {
  height: 47.5px;
  border-color: #ced4da;
}

.select2-container.select2-container--default .select2-selection--single .select2-selection__placeholder {
  opacity: 1;
  color: gray;
}

.select2-container.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 14px;
  line-height: 3.4em;
}

.select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 12px;
  right: 5px;
}

.select2-container .select2-results__option--highlighted.select2-results__option--highlighted {
  background-color: #1a25f1;
}

.file-picker {
  height: calc(2.25rem + 2px);
  width: 100%;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.file-picker--input {
  height: calc(2.25rem + 2px);
  opacity: 0;
  width: 100%;
  z-index: 2;
  margin: 0;
  position: relative;
}

.file-picker--label {
  height: calc(2.25rem + 2px);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.file-picker--label:after {
  color: #fff;
  height: 2.25rem;
  z-index: 3;
  background-color: #000;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.file-picker--input:lang(fr) ~ .file-picker--label:after {
  content: "Rechercher";
}

input:-webkit-autofill, input:-webkit-autofill:focus input:-webkit-autofill, input:-webkit-autofill:hover, select:-webkit-autofill, select:-webkit-autofill:focus, select:-webkit-autofill:hover, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
  -webkit-text-fill-color: inherit !important;
  border: none !important;
}

.ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-state-default.ui-state-active.ui-state-hover {
  border: none;
  background: #000 !important;
}

@media print {
  img.w-full, img.w-full-lg, img.w-full-md, img.w-full-sm, img.w-full-xs {
    width: auto !important;
    margin: 0 auto !important;
  }

  .btn, .pager, .pagination {
    display: none !important;
  }

  .slick-list.draggable {
    height: auto !important;
  }

  .slick-initialized .slick-slide, .slick-track {
    width: 100% !important;
    left: auto !important;
  }

  .slick-initialized .slick-slide {
    margin-bottom: 15px !important;
  }

  .slick-cloned, .parallax-mirror {
    display: none !important;
  }

  .wow, .wow * {
    opacity: 1 !important;
    visibility: visible !important;
  }

  a:link:after, a:visited:after {
    content: " (" attr(href) ")";
    display: none !important;
  }
}

.sprite {
  vertical-align: text-top;
  background-image: url("sprite.0bd84f57.png");
  background-repeat: no-repeat;
  display: inline-block;
}

.sprite.scroll-top {
  width: 30px;
  height: 30px;
  background-position: 0 0;
}

.sprite.skills-1 {
  width: 70px;
  height: 70px;
  background-position: -30px 0;
}

.sprite.skills-2 {
  width: 70px;
  height: 70px;
  background-position: -100px 0;
}

.sprite.skills-3 {
  width: 70px;
  height: 70px;
  background-position: -170px 0;
}

.sprite.skills-4 {
  width: 70px;
  height: 70px;
  background-position: -240px 0;
}

.sprite.skills-5 {
  width: 70px;
  height: 70px;
  background-position: -310px 0;
}

.sprite.search {
  width: 60px;
  height: 60px;
  background-position: -380px 0;
}

.sprite.arrow-regular-left {
  width: 60px;
  height: 60px;
  background-position: -30px -70px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-regular-left:hover {
  transform: scale(.9);
}

.sprite.arrow-regular-right {
  width: 60px;
  height: 60px;
  background-position: -90px -70px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-regular-right:hover {
  transform: scale(.9);
}

.sprite.arrow-alt-left {
  width: 60px;
  height: 60px;
  background-position: -150px -70px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-alt-left:hover {
  transform: scale(.9);
}

.sprite.arrow-alt-right {
  width: 60px;
  height: 60px;
  background-position: -210px -70px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-alt-right:hover {
  transform: scale(.9);
}

.sprite.arrow-small-left {
  width: 10px;
  height: 20px;
  background-position: -280px -80px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-small-left:hover {
  transform: scale(.9);
}

.sprite.arrow-small-right {
  width: 10px;
  height: 20px;
  background-position: -270px -80px;
  transition: all .1s ease-in-out;
}

.sprite.arrow-small-right:hover {
  transform: scale(.9);
}

.sprite.facebook-white {
  width: 30px;
  height: 30px;
  background-position: -290px -70px;
  transition: all .1s ease-in-out;
}

.sprite.facebook-white:hover {
  transform: translateY(-5px);
}

.sprite.instagram-white {
  width: 30px;
  height: 30px;
  background-position: -320px -70px;
  transition: all .1s ease-in-out;
}

.sprite.instagram-white:hover {
  transform: translateY(-5px);
}

.sprite.location-white {
  width: 30px;
  height: 30px;
  background-position: -350px -70px;
}

.sprite.facebook-black {
  width: 30px;
  height: 30px;
  background-position: -290px -100px;
  transition: all .1s ease-in-out;
}

.sprite.facebook-black:hover {
  transform: translateY(-5px);
}

.sprite.instagram-black {
  width: 30px;
  height: 30px;
  background-position: -320px -100px;
  transition: all .1s ease-in-out;
}

.sprite.instagram-black:hover {
  transform: translateY(-5px);
}

.ma-0 {
  margin: 0;
}

.pa-0 {
  padding: 0;
}

.mv-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mh-0 {
  margin-left: 0;
  margin-right: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.ph-0 {
  padding-left: 0;
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.ma-5 {
  margin: 5px;
}

.pa-5 {
  padding: 5px;
}

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.ma-10 {
  margin: 10px;
}

.pa-10 {
  padding: 10px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.ma-15 {
  margin: 15px;
}

.pa-15 {
  padding: 15px;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.ma-20 {
  margin: 20px;
}

.pa-20 {
  padding: 20px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.ma-25 {
  margin: 25px;
}

.pa-25 {
  padding: 25px;
}

.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mh-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.ma-30 {
  margin: 30px;
}

.pa-30 {
  padding: 30px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.ma-35 {
  margin: 35px;
}

.pa-35 {
  padding: 35px;
}

.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mh-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.ph-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.ma-40 {
  margin: 40px;
}

.pa-40 {
  padding: 40px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.ma-45 {
  margin: 45px;
}

.pa-45 {
  padding: 45px;
}

.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mh-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.ph-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.ma-50 {
  margin: 50px;
}

.pa-50 {
  padding: 50px;
}

.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mh-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.ma-55 {
  margin: 55px;
}

.pa-55 {
  padding: 55px;
}

.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mh-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.ph-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.ma-60 {
  margin: 60px;
}

.pa-60 {
  padding: 60px;
}

.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mh-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.ma-65 {
  margin: 65px;
}

.pa-65 {
  padding: 65px;
}

.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mh-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.ph-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.ma-70 {
  margin: 70px;
}

.pa-70 {
  padding: 70px;
}

.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mh-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ph-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.ma-75 {
  margin: 75px;
}

.pa-75 {
  padding: 75px;
}

.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mh-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.ph-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.ma-80 {
  margin: 80px;
}

.pa-80 {
  padding: 80px;
}

.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mh-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.ma-85 {
  margin: 85px;
}

.pa-85 {
  padding: 85px;
}

.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mh-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.ph-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.ma-90 {
  margin: 90px;
}

.pa-90 {
  padding: 90px;
}

.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mh-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ph-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.ma-95 {
  margin: 95px;
}

.pa-95 {
  padding: 95px;
}

.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mh-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.ph-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.ma-100 {
  margin: 100px;
}

.pa-100 {
  padding: 100px;
}

.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mh-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

@media (width >= 576px) {
  .ma-sm-0 {
    margin: 0;
  }

  .pa-sm-0 {
    padding: 0;
  }

  .mv-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mh-sm-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mr-sm-0 {
    margin-right: 0;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .ml-sm-0 {
    margin-left: 0;
  }

  .pv-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ph-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pr-sm-0 {
    padding-right: 0;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pl-sm-0 {
    padding-left: 0;
  }

  .ma-sm-5 {
    margin: 5px;
  }

  .pa-sm-5 {
    padding: 5px;
  }

  .mv-sm-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mh-sm-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mt-sm-5 {
    margin-top: 5px;
  }

  .mr-sm-5 {
    margin-right: 5px;
  }

  .mb-sm-5 {
    margin-bottom: 5px;
  }

  .ml-sm-5 {
    margin-left: 5px;
  }

  .pv-sm-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ph-sm-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pt-sm-5 {
    padding-top: 5px;
  }

  .pr-sm-5 {
    padding-right: 5px;
  }

  .pb-sm-5 {
    padding-bottom: 5px;
  }

  .pl-sm-5 {
    padding-left: 5px;
  }

  .ma-sm-10 {
    margin: 10px;
  }

  .pa-sm-10 {
    padding: 10px;
  }

  .mv-sm-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mh-sm-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt-sm-10 {
    margin-top: 10px;
  }

  .mr-sm-10 {
    margin-right: 10px;
  }

  .mb-sm-10 {
    margin-bottom: 10px;
  }

  .ml-sm-10 {
    margin-left: 10px;
  }

  .pv-sm-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ph-sm-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-sm-10 {
    padding-top: 10px;
  }

  .pr-sm-10 {
    padding-right: 10px;
  }

  .pb-sm-10 {
    padding-bottom: 10px;
  }

  .pl-sm-10 {
    padding-left: 10px;
  }

  .ma-sm-15 {
    margin: 15px;
  }

  .pa-sm-15 {
    padding: 15px;
  }

  .mv-sm-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mh-sm-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt-sm-15 {
    margin-top: 15px;
  }

  .mr-sm-15 {
    margin-right: 15px;
  }

  .mb-sm-15 {
    margin-bottom: 15px;
  }

  .ml-sm-15 {
    margin-left: 15px;
  }

  .pv-sm-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ph-sm-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pt-sm-15 {
    padding-top: 15px;
  }

  .pr-sm-15 {
    padding-right: 15px;
  }

  .pb-sm-15 {
    padding-bottom: 15px;
  }

  .pl-sm-15 {
    padding-left: 15px;
  }

  .ma-sm-20 {
    margin: 20px;
  }

  .pa-sm-20 {
    padding: 20px;
  }

  .mv-sm-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mh-sm-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-sm-20 {
    margin-top: 20px;
  }

  .mr-sm-20 {
    margin-right: 20px;
  }

  .mb-sm-20 {
    margin-bottom: 20px;
  }

  .ml-sm-20 {
    margin-left: 20px;
  }

  .pv-sm-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ph-sm-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt-sm-20 {
    padding-top: 20px;
  }

  .pr-sm-20 {
    padding-right: 20px;
  }

  .pb-sm-20 {
    padding-bottom: 20px;
  }

  .pl-sm-20 {
    padding-left: 20px;
  }

  .ma-sm-25 {
    margin: 25px;
  }

  .pa-sm-25 {
    padding: 25px;
  }

  .mv-sm-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mh-sm-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mt-sm-25 {
    margin-top: 25px;
  }

  .mr-sm-25 {
    margin-right: 25px;
  }

  .mb-sm-25 {
    margin-bottom: 25px;
  }

  .ml-sm-25 {
    margin-left: 25px;
  }

  .pv-sm-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .ph-sm-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pt-sm-25 {
    padding-top: 25px;
  }

  .pr-sm-25 {
    padding-right: 25px;
  }

  .pb-sm-25 {
    padding-bottom: 25px;
  }

  .pl-sm-25 {
    padding-left: 25px;
  }

  .ma-sm-30 {
    margin: 30px;
  }

  .pa-sm-30 {
    padding: 30px;
  }

  .mv-sm-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mh-sm-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mt-sm-30 {
    margin-top: 30px;
  }

  .mr-sm-30 {
    margin-right: 30px;
  }

  .mb-sm-30 {
    margin-bottom: 30px;
  }

  .ml-sm-30 {
    margin-left: 30px;
  }

  .pv-sm-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ph-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pt-sm-30 {
    padding-top: 30px;
  }

  .pr-sm-30 {
    padding-right: 30px;
  }

  .pb-sm-30 {
    padding-bottom: 30px;
  }

  .pl-sm-30 {
    padding-left: 30px;
  }

  .ma-sm-35 {
    margin: 35px;
  }

  .pa-sm-35 {
    padding: 35px;
  }

  .mv-sm-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mh-sm-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mt-sm-35 {
    margin-top: 35px;
  }

  .mr-sm-35 {
    margin-right: 35px;
  }

  .mb-sm-35 {
    margin-bottom: 35px;
  }

  .ml-sm-35 {
    margin-left: 35px;
  }

  .pv-sm-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ph-sm-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pt-sm-35 {
    padding-top: 35px;
  }

  .pr-sm-35 {
    padding-right: 35px;
  }

  .pb-sm-35 {
    padding-bottom: 35px;
  }

  .pl-sm-35 {
    padding-left: 35px;
  }

  .ma-sm-40 {
    margin: 40px;
  }

  .pa-sm-40 {
    padding: 40px;
  }

  .mv-sm-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mh-sm-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mt-sm-40 {
    margin-top: 40px;
  }

  .mr-sm-40 {
    margin-right: 40px;
  }

  .mb-sm-40 {
    margin-bottom: 40px;
  }

  .ml-sm-40 {
    margin-left: 40px;
  }

  .pv-sm-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ph-sm-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pt-sm-40 {
    padding-top: 40px;
  }

  .pr-sm-40 {
    padding-right: 40px;
  }

  .pb-sm-40 {
    padding-bottom: 40px;
  }

  .pl-sm-40 {
    padding-left: 40px;
  }

  .ma-sm-45 {
    margin: 45px;
  }

  .pa-sm-45 {
    padding: 45px;
  }

  .mv-sm-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mh-sm-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mt-sm-45 {
    margin-top: 45px;
  }

  .mr-sm-45 {
    margin-right: 45px;
  }

  .mb-sm-45 {
    margin-bottom: 45px;
  }

  .ml-sm-45 {
    margin-left: 45px;
  }

  .pv-sm-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .ph-sm-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pt-sm-45 {
    padding-top: 45px;
  }

  .pr-sm-45 {
    padding-right: 45px;
  }

  .pb-sm-45 {
    padding-bottom: 45px;
  }

  .pl-sm-45 {
    padding-left: 45px;
  }

  .ma-sm-50 {
    margin: 50px;
  }

  .pa-sm-50 {
    padding: 50px;
  }

  .mv-sm-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mh-sm-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mt-sm-50 {
    margin-top: 50px;
  }

  .mr-sm-50 {
    margin-right: 50px;
  }

  .mb-sm-50 {
    margin-bottom: 50px;
  }

  .ml-sm-50 {
    margin-left: 50px;
  }

  .pv-sm-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph-sm-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pt-sm-50 {
    padding-top: 50px;
  }

  .pr-sm-50 {
    padding-right: 50px;
  }

  .pb-sm-50 {
    padding-bottom: 50px;
  }

  .pl-sm-50 {
    padding-left: 50px;
  }

  .ma-sm-55 {
    margin: 55px;
  }

  .pa-sm-55 {
    padding: 55px;
  }

  .mv-sm-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mh-sm-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mt-sm-55 {
    margin-top: 55px;
  }

  .mr-sm-55 {
    margin-right: 55px;
  }

  .mb-sm-55 {
    margin-bottom: 55px;
  }

  .ml-sm-55 {
    margin-left: 55px;
  }

  .pv-sm-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .ph-sm-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pt-sm-55 {
    padding-top: 55px;
  }

  .pr-sm-55 {
    padding-right: 55px;
  }

  .pb-sm-55 {
    padding-bottom: 55px;
  }

  .pl-sm-55 {
    padding-left: 55px;
  }

  .ma-sm-60 {
    margin: 60px;
  }

  .pa-sm-60 {
    padding: 60px;
  }

  .mv-sm-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mh-sm-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mt-sm-60 {
    margin-top: 60px;
  }

  .mr-sm-60 {
    margin-right: 60px;
  }

  .mb-sm-60 {
    margin-bottom: 60px;
  }

  .ml-sm-60 {
    margin-left: 60px;
  }

  .pv-sm-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ph-sm-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pt-sm-60 {
    padding-top: 60px;
  }

  .pr-sm-60 {
    padding-right: 60px;
  }

  .pb-sm-60 {
    padding-bottom: 60px;
  }

  .pl-sm-60 {
    padding-left: 60px;
  }

  .ma-sm-65 {
    margin: 65px;
  }

  .pa-sm-65 {
    padding: 65px;
  }

  .mv-sm-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mh-sm-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mt-sm-65 {
    margin-top: 65px;
  }

  .mr-sm-65 {
    margin-right: 65px;
  }

  .mb-sm-65 {
    margin-bottom: 65px;
  }

  .ml-sm-65 {
    margin-left: 65px;
  }

  .pv-sm-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .ph-sm-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pt-sm-65 {
    padding-top: 65px;
  }

  .pr-sm-65 {
    padding-right: 65px;
  }

  .pb-sm-65 {
    padding-bottom: 65px;
  }

  .pl-sm-65 {
    padding-left: 65px;
  }

  .ma-sm-70 {
    margin: 70px;
  }

  .pa-sm-70 {
    padding: 70px;
  }

  .mv-sm-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mh-sm-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mt-sm-70 {
    margin-top: 70px;
  }

  .mr-sm-70 {
    margin-right: 70px;
  }

  .mb-sm-70 {
    margin-bottom: 70px;
  }

  .ml-sm-70 {
    margin-left: 70px;
  }

  .pv-sm-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ph-sm-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pt-sm-70 {
    padding-top: 70px;
  }

  .pr-sm-70 {
    padding-right: 70px;
  }

  .pb-sm-70 {
    padding-bottom: 70px;
  }

  .pl-sm-70 {
    padding-left: 70px;
  }

  .ma-sm-75 {
    margin: 75px;
  }

  .pa-sm-75 {
    padding: 75px;
  }

  .mv-sm-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mh-sm-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mt-sm-75 {
    margin-top: 75px;
  }

  .mr-sm-75 {
    margin-right: 75px;
  }

  .mb-sm-75 {
    margin-bottom: 75px;
  }

  .ml-sm-75 {
    margin-left: 75px;
  }

  .pv-sm-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ph-sm-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pt-sm-75 {
    padding-top: 75px;
  }

  .pr-sm-75 {
    padding-right: 75px;
  }

  .pb-sm-75 {
    padding-bottom: 75px;
  }

  .pl-sm-75 {
    padding-left: 75px;
  }

  .ma-sm-80 {
    margin: 80px;
  }

  .pa-sm-80 {
    padding: 80px;
  }

  .mv-sm-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mh-sm-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mt-sm-80 {
    margin-top: 80px;
  }

  .mr-sm-80 {
    margin-right: 80px;
  }

  .mb-sm-80 {
    margin-bottom: 80px;
  }

  .ml-sm-80 {
    margin-left: 80px;
  }

  .pv-sm-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .ph-sm-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pt-sm-80 {
    padding-top: 80px;
  }

  .pr-sm-80 {
    padding-right: 80px;
  }

  .pb-sm-80 {
    padding-bottom: 80px;
  }

  .pl-sm-80 {
    padding-left: 80px;
  }

  .ma-sm-85 {
    margin: 85px;
  }

  .pa-sm-85 {
    padding: 85px;
  }

  .mv-sm-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mh-sm-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mt-sm-85 {
    margin-top: 85px;
  }

  .mr-sm-85 {
    margin-right: 85px;
  }

  .mb-sm-85 {
    margin-bottom: 85px;
  }

  .ml-sm-85 {
    margin-left: 85px;
  }

  .pv-sm-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .ph-sm-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pt-sm-85 {
    padding-top: 85px;
  }

  .pr-sm-85 {
    padding-right: 85px;
  }

  .pb-sm-85 {
    padding-bottom: 85px;
  }

  .pl-sm-85 {
    padding-left: 85px;
  }

  .ma-sm-90 {
    margin: 90px;
  }

  .pa-sm-90 {
    padding: 90px;
  }

  .mv-sm-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mh-sm-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mt-sm-90 {
    margin-top: 90px;
  }

  .mr-sm-90 {
    margin-right: 90px;
  }

  .mb-sm-90 {
    margin-bottom: 90px;
  }

  .ml-sm-90 {
    margin-left: 90px;
  }

  .pv-sm-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ph-sm-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pt-sm-90 {
    padding-top: 90px;
  }

  .pr-sm-90 {
    padding-right: 90px;
  }

  .pb-sm-90 {
    padding-bottom: 90px;
  }

  .pl-sm-90 {
    padding-left: 90px;
  }

  .ma-sm-95 {
    margin: 95px;
  }

  .pa-sm-95 {
    padding: 95px;
  }

  .mv-sm-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mh-sm-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mt-sm-95 {
    margin-top: 95px;
  }

  .mr-sm-95 {
    margin-right: 95px;
  }

  .mb-sm-95 {
    margin-bottom: 95px;
  }

  .ml-sm-95 {
    margin-left: 95px;
  }

  .pv-sm-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .ph-sm-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pt-sm-95 {
    padding-top: 95px;
  }

  .pr-sm-95 {
    padding-right: 95px;
  }

  .pb-sm-95 {
    padding-bottom: 95px;
  }

  .pl-sm-95 {
    padding-left: 95px;
  }

  .ma-sm-100 {
    margin: 100px;
  }

  .pa-sm-100 {
    padding: 100px;
  }

  .mv-sm-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mh-sm-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mt-sm-100 {
    margin-top: 100px;
  }

  .mr-sm-100 {
    margin-right: 100px;
  }

  .mb-sm-100 {
    margin-bottom: 100px;
  }

  .ml-sm-100 {
    margin-left: 100px;
  }

  .pv-sm-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ph-sm-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pt-sm-100 {
    padding-top: 100px;
  }

  .pr-sm-100 {
    padding-right: 100px;
  }

  .pb-sm-100 {
    padding-bottom: 100px;
  }

  .pl-sm-100 {
    padding-left: 100px;
  }
}

@media (width >= 768px) {
  .ma-md-0 {
    margin: 0;
  }

  .pa-md-0 {
    padding: 0;
  }

  .mv-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mh-md-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mr-md-0 {
    margin-right: 0;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .ml-md-0 {
    margin-left: 0;
  }

  .pv-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ph-md-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pr-md-0 {
    padding-right: 0;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pl-md-0 {
    padding-left: 0;
  }

  .ma-md-5 {
    margin: 5px;
  }

  .pa-md-5 {
    padding: 5px;
  }

  .mv-md-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mh-md-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mt-md-5 {
    margin-top: 5px;
  }

  .mr-md-5 {
    margin-right: 5px;
  }

  .mb-md-5 {
    margin-bottom: 5px;
  }

  .ml-md-5 {
    margin-left: 5px;
  }

  .pv-md-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ph-md-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pt-md-5 {
    padding-top: 5px;
  }

  .pr-md-5 {
    padding-right: 5px;
  }

  .pb-md-5 {
    padding-bottom: 5px;
  }

  .pl-md-5 {
    padding-left: 5px;
  }

  .ma-md-10 {
    margin: 10px;
  }

  .pa-md-10 {
    padding: 10px;
  }

  .mv-md-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mh-md-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt-md-10 {
    margin-top: 10px;
  }

  .mr-md-10 {
    margin-right: 10px;
  }

  .mb-md-10 {
    margin-bottom: 10px;
  }

  .ml-md-10 {
    margin-left: 10px;
  }

  .pv-md-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ph-md-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-md-10 {
    padding-top: 10px;
  }

  .pr-md-10 {
    padding-right: 10px;
  }

  .pb-md-10 {
    padding-bottom: 10px;
  }

  .pl-md-10 {
    padding-left: 10px;
  }

  .ma-md-15 {
    margin: 15px;
  }

  .pa-md-15 {
    padding: 15px;
  }

  .mv-md-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mh-md-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt-md-15 {
    margin-top: 15px;
  }

  .mr-md-15 {
    margin-right: 15px;
  }

  .mb-md-15 {
    margin-bottom: 15px;
  }

  .ml-md-15 {
    margin-left: 15px;
  }

  .pv-md-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ph-md-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pt-md-15 {
    padding-top: 15px;
  }

  .pr-md-15 {
    padding-right: 15px;
  }

  .pb-md-15 {
    padding-bottom: 15px;
  }

  .pl-md-15 {
    padding-left: 15px;
  }

  .ma-md-20 {
    margin: 20px;
  }

  .pa-md-20 {
    padding: 20px;
  }

  .mv-md-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mh-md-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-md-20 {
    margin-top: 20px;
  }

  .mr-md-20 {
    margin-right: 20px;
  }

  .mb-md-20 {
    margin-bottom: 20px;
  }

  .ml-md-20 {
    margin-left: 20px;
  }

  .pv-md-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ph-md-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt-md-20 {
    padding-top: 20px;
  }

  .pr-md-20 {
    padding-right: 20px;
  }

  .pb-md-20 {
    padding-bottom: 20px;
  }

  .pl-md-20 {
    padding-left: 20px;
  }

  .ma-md-25 {
    margin: 25px;
  }

  .pa-md-25 {
    padding: 25px;
  }

  .mv-md-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mh-md-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mt-md-25 {
    margin-top: 25px;
  }

  .mr-md-25 {
    margin-right: 25px;
  }

  .mb-md-25 {
    margin-bottom: 25px;
  }

  .ml-md-25 {
    margin-left: 25px;
  }

  .pv-md-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .ph-md-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pt-md-25 {
    padding-top: 25px;
  }

  .pr-md-25 {
    padding-right: 25px;
  }

  .pb-md-25 {
    padding-bottom: 25px;
  }

  .pl-md-25 {
    padding-left: 25px;
  }

  .ma-md-30 {
    margin: 30px;
  }

  .pa-md-30 {
    padding: 30px;
  }

  .mv-md-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mh-md-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mt-md-30 {
    margin-top: 30px;
  }

  .mr-md-30 {
    margin-right: 30px;
  }

  .mb-md-30 {
    margin-bottom: 30px;
  }

  .ml-md-30 {
    margin-left: 30px;
  }

  .pv-md-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ph-md-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pt-md-30 {
    padding-top: 30px;
  }

  .pr-md-30 {
    padding-right: 30px;
  }

  .pb-md-30 {
    padding-bottom: 30px;
  }

  .pl-md-30 {
    padding-left: 30px;
  }

  .ma-md-35 {
    margin: 35px;
  }

  .pa-md-35 {
    padding: 35px;
  }

  .mv-md-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mh-md-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mt-md-35 {
    margin-top: 35px;
  }

  .mr-md-35 {
    margin-right: 35px;
  }

  .mb-md-35 {
    margin-bottom: 35px;
  }

  .ml-md-35 {
    margin-left: 35px;
  }

  .pv-md-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ph-md-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pt-md-35 {
    padding-top: 35px;
  }

  .pr-md-35 {
    padding-right: 35px;
  }

  .pb-md-35 {
    padding-bottom: 35px;
  }

  .pl-md-35 {
    padding-left: 35px;
  }

  .ma-md-40 {
    margin: 40px;
  }

  .pa-md-40 {
    padding: 40px;
  }

  .mv-md-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mh-md-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mt-md-40 {
    margin-top: 40px;
  }

  .mr-md-40 {
    margin-right: 40px;
  }

  .mb-md-40 {
    margin-bottom: 40px;
  }

  .ml-md-40 {
    margin-left: 40px;
  }

  .pv-md-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ph-md-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pt-md-40 {
    padding-top: 40px;
  }

  .pr-md-40 {
    padding-right: 40px;
  }

  .pb-md-40 {
    padding-bottom: 40px;
  }

  .pl-md-40 {
    padding-left: 40px;
  }

  .ma-md-45 {
    margin: 45px;
  }

  .pa-md-45 {
    padding: 45px;
  }

  .mv-md-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mh-md-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mt-md-45 {
    margin-top: 45px;
  }

  .mr-md-45 {
    margin-right: 45px;
  }

  .mb-md-45 {
    margin-bottom: 45px;
  }

  .ml-md-45 {
    margin-left: 45px;
  }

  .pv-md-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .ph-md-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pt-md-45 {
    padding-top: 45px;
  }

  .pr-md-45 {
    padding-right: 45px;
  }

  .pb-md-45 {
    padding-bottom: 45px;
  }

  .pl-md-45 {
    padding-left: 45px;
  }

  .ma-md-50 {
    margin: 50px;
  }

  .pa-md-50 {
    padding: 50px;
  }

  .mv-md-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mh-md-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mt-md-50 {
    margin-top: 50px;
  }

  .mr-md-50 {
    margin-right: 50px;
  }

  .mb-md-50 {
    margin-bottom: 50px;
  }

  .ml-md-50 {
    margin-left: 50px;
  }

  .pv-md-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph-md-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pt-md-50 {
    padding-top: 50px;
  }

  .pr-md-50 {
    padding-right: 50px;
  }

  .pb-md-50 {
    padding-bottom: 50px;
  }

  .pl-md-50 {
    padding-left: 50px;
  }

  .ma-md-55 {
    margin: 55px;
  }

  .pa-md-55 {
    padding: 55px;
  }

  .mv-md-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mh-md-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mt-md-55 {
    margin-top: 55px;
  }

  .mr-md-55 {
    margin-right: 55px;
  }

  .mb-md-55 {
    margin-bottom: 55px;
  }

  .ml-md-55 {
    margin-left: 55px;
  }

  .pv-md-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .ph-md-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pt-md-55 {
    padding-top: 55px;
  }

  .pr-md-55 {
    padding-right: 55px;
  }

  .pb-md-55 {
    padding-bottom: 55px;
  }

  .pl-md-55 {
    padding-left: 55px;
  }

  .ma-md-60 {
    margin: 60px;
  }

  .pa-md-60 {
    padding: 60px;
  }

  .mv-md-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mh-md-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mt-md-60 {
    margin-top: 60px;
  }

  .mr-md-60 {
    margin-right: 60px;
  }

  .mb-md-60 {
    margin-bottom: 60px;
  }

  .ml-md-60 {
    margin-left: 60px;
  }

  .pv-md-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ph-md-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pt-md-60 {
    padding-top: 60px;
  }

  .pr-md-60 {
    padding-right: 60px;
  }

  .pb-md-60 {
    padding-bottom: 60px;
  }

  .pl-md-60 {
    padding-left: 60px;
  }

  .ma-md-65 {
    margin: 65px;
  }

  .pa-md-65 {
    padding: 65px;
  }

  .mv-md-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mh-md-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mt-md-65 {
    margin-top: 65px;
  }

  .mr-md-65 {
    margin-right: 65px;
  }

  .mb-md-65 {
    margin-bottom: 65px;
  }

  .ml-md-65 {
    margin-left: 65px;
  }

  .pv-md-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .ph-md-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pt-md-65 {
    padding-top: 65px;
  }

  .pr-md-65 {
    padding-right: 65px;
  }

  .pb-md-65 {
    padding-bottom: 65px;
  }

  .pl-md-65 {
    padding-left: 65px;
  }

  .ma-md-70 {
    margin: 70px;
  }

  .pa-md-70 {
    padding: 70px;
  }

  .mv-md-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mh-md-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mt-md-70 {
    margin-top: 70px;
  }

  .mr-md-70 {
    margin-right: 70px;
  }

  .mb-md-70 {
    margin-bottom: 70px;
  }

  .ml-md-70 {
    margin-left: 70px;
  }

  .pv-md-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ph-md-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pt-md-70 {
    padding-top: 70px;
  }

  .pr-md-70 {
    padding-right: 70px;
  }

  .pb-md-70 {
    padding-bottom: 70px;
  }

  .pl-md-70 {
    padding-left: 70px;
  }

  .ma-md-75 {
    margin: 75px;
  }

  .pa-md-75 {
    padding: 75px;
  }

  .mv-md-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mh-md-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mt-md-75 {
    margin-top: 75px;
  }

  .mr-md-75 {
    margin-right: 75px;
  }

  .mb-md-75 {
    margin-bottom: 75px;
  }

  .ml-md-75 {
    margin-left: 75px;
  }

  .pv-md-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ph-md-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pt-md-75 {
    padding-top: 75px;
  }

  .pr-md-75 {
    padding-right: 75px;
  }

  .pb-md-75 {
    padding-bottom: 75px;
  }

  .pl-md-75 {
    padding-left: 75px;
  }

  .ma-md-80 {
    margin: 80px;
  }

  .pa-md-80 {
    padding: 80px;
  }

  .mv-md-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mh-md-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mt-md-80 {
    margin-top: 80px;
  }

  .mr-md-80 {
    margin-right: 80px;
  }

  .mb-md-80 {
    margin-bottom: 80px;
  }

  .ml-md-80 {
    margin-left: 80px;
  }

  .pv-md-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .ph-md-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pt-md-80 {
    padding-top: 80px;
  }

  .pr-md-80 {
    padding-right: 80px;
  }

  .pb-md-80 {
    padding-bottom: 80px;
  }

  .pl-md-80 {
    padding-left: 80px;
  }

  .ma-md-85 {
    margin: 85px;
  }

  .pa-md-85 {
    padding: 85px;
  }

  .mv-md-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mh-md-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mt-md-85 {
    margin-top: 85px;
  }

  .mr-md-85 {
    margin-right: 85px;
  }

  .mb-md-85 {
    margin-bottom: 85px;
  }

  .ml-md-85 {
    margin-left: 85px;
  }

  .pv-md-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .ph-md-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pt-md-85 {
    padding-top: 85px;
  }

  .pr-md-85 {
    padding-right: 85px;
  }

  .pb-md-85 {
    padding-bottom: 85px;
  }

  .pl-md-85 {
    padding-left: 85px;
  }

  .ma-md-90 {
    margin: 90px;
  }

  .pa-md-90 {
    padding: 90px;
  }

  .mv-md-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mh-md-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mt-md-90 {
    margin-top: 90px;
  }

  .mr-md-90 {
    margin-right: 90px;
  }

  .mb-md-90 {
    margin-bottom: 90px;
  }

  .ml-md-90 {
    margin-left: 90px;
  }

  .pv-md-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ph-md-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pt-md-90 {
    padding-top: 90px;
  }

  .pr-md-90 {
    padding-right: 90px;
  }

  .pb-md-90 {
    padding-bottom: 90px;
  }

  .pl-md-90 {
    padding-left: 90px;
  }

  .ma-md-95 {
    margin: 95px;
  }

  .pa-md-95 {
    padding: 95px;
  }

  .mv-md-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mh-md-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mt-md-95 {
    margin-top: 95px;
  }

  .mr-md-95 {
    margin-right: 95px;
  }

  .mb-md-95 {
    margin-bottom: 95px;
  }

  .ml-md-95 {
    margin-left: 95px;
  }

  .pv-md-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .ph-md-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pt-md-95 {
    padding-top: 95px;
  }

  .pr-md-95 {
    padding-right: 95px;
  }

  .pb-md-95 {
    padding-bottom: 95px;
  }

  .pl-md-95 {
    padding-left: 95px;
  }

  .ma-md-100 {
    margin: 100px;
  }

  .pa-md-100 {
    padding: 100px;
  }

  .mv-md-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mh-md-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mt-md-100 {
    margin-top: 100px;
  }

  .mr-md-100 {
    margin-right: 100px;
  }

  .mb-md-100 {
    margin-bottom: 100px;
  }

  .ml-md-100 {
    margin-left: 100px;
  }

  .pv-md-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ph-md-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pt-md-100 {
    padding-top: 100px;
  }

  .pr-md-100 {
    padding-right: 100px;
  }

  .pb-md-100 {
    padding-bottom: 100px;
  }

  .pl-md-100 {
    padding-left: 100px;
  }
}

@media (width >= 992px) {
  .ma-lg-0 {
    margin: 0;
  }

  .pa-lg-0 {
    padding: 0;
  }

  .mv-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mh-lg-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mr-lg-0 {
    margin-right: 0;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .ml-lg-0 {
    margin-left: 0;
  }

  .pv-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ph-lg-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pr-lg-0 {
    padding-right: 0;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pl-lg-0 {
    padding-left: 0;
  }

  .ma-lg-5 {
    margin: 5px;
  }

  .pa-lg-5 {
    padding: 5px;
  }

  .mv-lg-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mh-lg-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mt-lg-5 {
    margin-top: 5px;
  }

  .mr-lg-5 {
    margin-right: 5px;
  }

  .mb-lg-5 {
    margin-bottom: 5px;
  }

  .ml-lg-5 {
    margin-left: 5px;
  }

  .pv-lg-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ph-lg-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pt-lg-5 {
    padding-top: 5px;
  }

  .pr-lg-5 {
    padding-right: 5px;
  }

  .pb-lg-5 {
    padding-bottom: 5px;
  }

  .pl-lg-5 {
    padding-left: 5px;
  }

  .ma-lg-10 {
    margin: 10px;
  }

  .pa-lg-10 {
    padding: 10px;
  }

  .mv-lg-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mh-lg-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt-lg-10 {
    margin-top: 10px;
  }

  .mr-lg-10 {
    margin-right: 10px;
  }

  .mb-lg-10 {
    margin-bottom: 10px;
  }

  .ml-lg-10 {
    margin-left: 10px;
  }

  .pv-lg-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ph-lg-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-lg-10 {
    padding-top: 10px;
  }

  .pr-lg-10 {
    padding-right: 10px;
  }

  .pb-lg-10 {
    padding-bottom: 10px;
  }

  .pl-lg-10 {
    padding-left: 10px;
  }

  .ma-lg-15 {
    margin: 15px;
  }

  .pa-lg-15 {
    padding: 15px;
  }

  .mv-lg-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mh-lg-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt-lg-15 {
    margin-top: 15px;
  }

  .mr-lg-15 {
    margin-right: 15px;
  }

  .mb-lg-15 {
    margin-bottom: 15px;
  }

  .ml-lg-15 {
    margin-left: 15px;
  }

  .pv-lg-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ph-lg-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pt-lg-15 {
    padding-top: 15px;
  }

  .pr-lg-15 {
    padding-right: 15px;
  }

  .pb-lg-15 {
    padding-bottom: 15px;
  }

  .pl-lg-15 {
    padding-left: 15px;
  }

  .ma-lg-20 {
    margin: 20px;
  }

  .pa-lg-20 {
    padding: 20px;
  }

  .mv-lg-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mh-lg-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-lg-20 {
    margin-top: 20px;
  }

  .mr-lg-20 {
    margin-right: 20px;
  }

  .mb-lg-20 {
    margin-bottom: 20px;
  }

  .ml-lg-20 {
    margin-left: 20px;
  }

  .pv-lg-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ph-lg-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt-lg-20 {
    padding-top: 20px;
  }

  .pr-lg-20 {
    padding-right: 20px;
  }

  .pb-lg-20 {
    padding-bottom: 20px;
  }

  .pl-lg-20 {
    padding-left: 20px;
  }

  .ma-lg-25 {
    margin: 25px;
  }

  .pa-lg-25 {
    padding: 25px;
  }

  .mv-lg-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mh-lg-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mt-lg-25 {
    margin-top: 25px;
  }

  .mr-lg-25 {
    margin-right: 25px;
  }

  .mb-lg-25 {
    margin-bottom: 25px;
  }

  .ml-lg-25 {
    margin-left: 25px;
  }

  .pv-lg-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .ph-lg-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pt-lg-25 {
    padding-top: 25px;
  }

  .pr-lg-25 {
    padding-right: 25px;
  }

  .pb-lg-25 {
    padding-bottom: 25px;
  }

  .pl-lg-25 {
    padding-left: 25px;
  }

  .ma-lg-30 {
    margin: 30px;
  }

  .pa-lg-30 {
    padding: 30px;
  }

  .mv-lg-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mh-lg-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mt-lg-30 {
    margin-top: 30px;
  }

  .mr-lg-30 {
    margin-right: 30px;
  }

  .mb-lg-30 {
    margin-bottom: 30px;
  }

  .ml-lg-30 {
    margin-left: 30px;
  }

  .pv-lg-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ph-lg-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pt-lg-30 {
    padding-top: 30px;
  }

  .pr-lg-30 {
    padding-right: 30px;
  }

  .pb-lg-30 {
    padding-bottom: 30px;
  }

  .pl-lg-30 {
    padding-left: 30px;
  }

  .ma-lg-35 {
    margin: 35px;
  }

  .pa-lg-35 {
    padding: 35px;
  }

  .mv-lg-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mh-lg-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mt-lg-35 {
    margin-top: 35px;
  }

  .mr-lg-35 {
    margin-right: 35px;
  }

  .mb-lg-35 {
    margin-bottom: 35px;
  }

  .ml-lg-35 {
    margin-left: 35px;
  }

  .pv-lg-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ph-lg-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pt-lg-35 {
    padding-top: 35px;
  }

  .pr-lg-35 {
    padding-right: 35px;
  }

  .pb-lg-35 {
    padding-bottom: 35px;
  }

  .pl-lg-35 {
    padding-left: 35px;
  }

  .ma-lg-40 {
    margin: 40px;
  }

  .pa-lg-40 {
    padding: 40px;
  }

  .mv-lg-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mh-lg-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mt-lg-40 {
    margin-top: 40px;
  }

  .mr-lg-40 {
    margin-right: 40px;
  }

  .mb-lg-40 {
    margin-bottom: 40px;
  }

  .ml-lg-40 {
    margin-left: 40px;
  }

  .pv-lg-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ph-lg-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pt-lg-40 {
    padding-top: 40px;
  }

  .pr-lg-40 {
    padding-right: 40px;
  }

  .pb-lg-40 {
    padding-bottom: 40px;
  }

  .pl-lg-40 {
    padding-left: 40px;
  }

  .ma-lg-45 {
    margin: 45px;
  }

  .pa-lg-45 {
    padding: 45px;
  }

  .mv-lg-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mh-lg-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mt-lg-45 {
    margin-top: 45px;
  }

  .mr-lg-45 {
    margin-right: 45px;
  }

  .mb-lg-45 {
    margin-bottom: 45px;
  }

  .ml-lg-45 {
    margin-left: 45px;
  }

  .pv-lg-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .ph-lg-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pt-lg-45 {
    padding-top: 45px;
  }

  .pr-lg-45 {
    padding-right: 45px;
  }

  .pb-lg-45 {
    padding-bottom: 45px;
  }

  .pl-lg-45 {
    padding-left: 45px;
  }

  .ma-lg-50 {
    margin: 50px;
  }

  .pa-lg-50 {
    padding: 50px;
  }

  .mv-lg-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mh-lg-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mt-lg-50 {
    margin-top: 50px;
  }

  .mr-lg-50 {
    margin-right: 50px;
  }

  .mb-lg-50 {
    margin-bottom: 50px;
  }

  .ml-lg-50 {
    margin-left: 50px;
  }

  .pv-lg-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph-lg-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pt-lg-50 {
    padding-top: 50px;
  }

  .pr-lg-50 {
    padding-right: 50px;
  }

  .pb-lg-50 {
    padding-bottom: 50px;
  }

  .pl-lg-50 {
    padding-left: 50px;
  }

  .ma-lg-55 {
    margin: 55px;
  }

  .pa-lg-55 {
    padding: 55px;
  }

  .mv-lg-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mh-lg-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mt-lg-55 {
    margin-top: 55px;
  }

  .mr-lg-55 {
    margin-right: 55px;
  }

  .mb-lg-55 {
    margin-bottom: 55px;
  }

  .ml-lg-55 {
    margin-left: 55px;
  }

  .pv-lg-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .ph-lg-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pt-lg-55 {
    padding-top: 55px;
  }

  .pr-lg-55 {
    padding-right: 55px;
  }

  .pb-lg-55 {
    padding-bottom: 55px;
  }

  .pl-lg-55 {
    padding-left: 55px;
  }

  .ma-lg-60 {
    margin: 60px;
  }

  .pa-lg-60 {
    padding: 60px;
  }

  .mv-lg-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mh-lg-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mt-lg-60 {
    margin-top: 60px;
  }

  .mr-lg-60 {
    margin-right: 60px;
  }

  .mb-lg-60 {
    margin-bottom: 60px;
  }

  .ml-lg-60 {
    margin-left: 60px;
  }

  .pv-lg-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ph-lg-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pt-lg-60 {
    padding-top: 60px;
  }

  .pr-lg-60 {
    padding-right: 60px;
  }

  .pb-lg-60 {
    padding-bottom: 60px;
  }

  .pl-lg-60 {
    padding-left: 60px;
  }

  .ma-lg-65 {
    margin: 65px;
  }

  .pa-lg-65 {
    padding: 65px;
  }

  .mv-lg-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mh-lg-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mt-lg-65 {
    margin-top: 65px;
  }

  .mr-lg-65 {
    margin-right: 65px;
  }

  .mb-lg-65 {
    margin-bottom: 65px;
  }

  .ml-lg-65 {
    margin-left: 65px;
  }

  .pv-lg-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .ph-lg-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pt-lg-65 {
    padding-top: 65px;
  }

  .pr-lg-65 {
    padding-right: 65px;
  }

  .pb-lg-65 {
    padding-bottom: 65px;
  }

  .pl-lg-65 {
    padding-left: 65px;
  }

  .ma-lg-70 {
    margin: 70px;
  }

  .pa-lg-70 {
    padding: 70px;
  }

  .mv-lg-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mh-lg-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mt-lg-70 {
    margin-top: 70px;
  }

  .mr-lg-70 {
    margin-right: 70px;
  }

  .mb-lg-70 {
    margin-bottom: 70px;
  }

  .ml-lg-70 {
    margin-left: 70px;
  }

  .pv-lg-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ph-lg-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pt-lg-70 {
    padding-top: 70px;
  }

  .pr-lg-70 {
    padding-right: 70px;
  }

  .pb-lg-70 {
    padding-bottom: 70px;
  }

  .pl-lg-70 {
    padding-left: 70px;
  }

  .ma-lg-75 {
    margin: 75px;
  }

  .pa-lg-75 {
    padding: 75px;
  }

  .mv-lg-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mh-lg-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mt-lg-75 {
    margin-top: 75px;
  }

  .mr-lg-75 {
    margin-right: 75px;
  }

  .mb-lg-75 {
    margin-bottom: 75px;
  }

  .ml-lg-75 {
    margin-left: 75px;
  }

  .pv-lg-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ph-lg-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pt-lg-75 {
    padding-top: 75px;
  }

  .pr-lg-75 {
    padding-right: 75px;
  }

  .pb-lg-75 {
    padding-bottom: 75px;
  }

  .pl-lg-75 {
    padding-left: 75px;
  }

  .ma-lg-80 {
    margin: 80px;
  }

  .pa-lg-80 {
    padding: 80px;
  }

  .mv-lg-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mh-lg-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mt-lg-80 {
    margin-top: 80px;
  }

  .mr-lg-80 {
    margin-right: 80px;
  }

  .mb-lg-80 {
    margin-bottom: 80px;
  }

  .ml-lg-80 {
    margin-left: 80px;
  }

  .pv-lg-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .ph-lg-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pt-lg-80 {
    padding-top: 80px;
  }

  .pr-lg-80 {
    padding-right: 80px;
  }

  .pb-lg-80 {
    padding-bottom: 80px;
  }

  .pl-lg-80 {
    padding-left: 80px;
  }

  .ma-lg-85 {
    margin: 85px;
  }

  .pa-lg-85 {
    padding: 85px;
  }

  .mv-lg-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mh-lg-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mt-lg-85 {
    margin-top: 85px;
  }

  .mr-lg-85 {
    margin-right: 85px;
  }

  .mb-lg-85 {
    margin-bottom: 85px;
  }

  .ml-lg-85 {
    margin-left: 85px;
  }

  .pv-lg-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .ph-lg-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pt-lg-85 {
    padding-top: 85px;
  }

  .pr-lg-85 {
    padding-right: 85px;
  }

  .pb-lg-85 {
    padding-bottom: 85px;
  }

  .pl-lg-85 {
    padding-left: 85px;
  }

  .ma-lg-90 {
    margin: 90px;
  }

  .pa-lg-90 {
    padding: 90px;
  }

  .mv-lg-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mh-lg-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mt-lg-90 {
    margin-top: 90px;
  }

  .mr-lg-90 {
    margin-right: 90px;
  }

  .mb-lg-90 {
    margin-bottom: 90px;
  }

  .ml-lg-90 {
    margin-left: 90px;
  }

  .pv-lg-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ph-lg-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pt-lg-90 {
    padding-top: 90px;
  }

  .pr-lg-90 {
    padding-right: 90px;
  }

  .pb-lg-90 {
    padding-bottom: 90px;
  }

  .pl-lg-90 {
    padding-left: 90px;
  }

  .ma-lg-95 {
    margin: 95px;
  }

  .pa-lg-95 {
    padding: 95px;
  }

  .mv-lg-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mh-lg-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mt-lg-95 {
    margin-top: 95px;
  }

  .mr-lg-95 {
    margin-right: 95px;
  }

  .mb-lg-95 {
    margin-bottom: 95px;
  }

  .ml-lg-95 {
    margin-left: 95px;
  }

  .pv-lg-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .ph-lg-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pt-lg-95 {
    padding-top: 95px;
  }

  .pr-lg-95 {
    padding-right: 95px;
  }

  .pb-lg-95 {
    padding-bottom: 95px;
  }

  .pl-lg-95 {
    padding-left: 95px;
  }

  .ma-lg-100 {
    margin: 100px;
  }

  .pa-lg-100 {
    padding: 100px;
  }

  .mv-lg-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mh-lg-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mt-lg-100 {
    margin-top: 100px;
  }

  .mr-lg-100 {
    margin-right: 100px;
  }

  .mb-lg-100 {
    margin-bottom: 100px;
  }

  .ml-lg-100 {
    margin-left: 100px;
  }

  .pv-lg-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ph-lg-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pt-lg-100 {
    padding-top: 100px;
  }

  .pr-lg-100 {
    padding-right: 100px;
  }

  .pb-lg-100 {
    padding-bottom: 100px;
  }

  .pl-lg-100 {
    padding-left: 100px;
  }
}

@media (width >= 1200px) {
  .ma-xl-0 {
    margin: 0;
  }

  .pa-xl-0 {
    padding: 0;
  }

  .mv-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mh-xl-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mr-xl-0 {
    margin-right: 0;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .ml-xl-0 {
    margin-left: 0;
  }

  .pv-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ph-xl-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pr-xl-0 {
    padding-right: 0;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pl-xl-0 {
    padding-left: 0;
  }

  .ma-xl-5 {
    margin: 5px;
  }

  .pa-xl-5 {
    padding: 5px;
  }

  .mv-xl-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mh-xl-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mt-xl-5 {
    margin-top: 5px;
  }

  .mr-xl-5 {
    margin-right: 5px;
  }

  .mb-xl-5 {
    margin-bottom: 5px;
  }

  .ml-xl-5 {
    margin-left: 5px;
  }

  .pv-xl-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ph-xl-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pt-xl-5 {
    padding-top: 5px;
  }

  .pr-xl-5 {
    padding-right: 5px;
  }

  .pb-xl-5 {
    padding-bottom: 5px;
  }

  .pl-xl-5 {
    padding-left: 5px;
  }

  .ma-xl-10 {
    margin: 10px;
  }

  .pa-xl-10 {
    padding: 10px;
  }

  .mv-xl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mh-xl-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt-xl-10 {
    margin-top: 10px;
  }

  .mr-xl-10 {
    margin-right: 10px;
  }

  .mb-xl-10 {
    margin-bottom: 10px;
  }

  .ml-xl-10 {
    margin-left: 10px;
  }

  .pv-xl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ph-xl-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-xl-10 {
    padding-top: 10px;
  }

  .pr-xl-10 {
    padding-right: 10px;
  }

  .pb-xl-10 {
    padding-bottom: 10px;
  }

  .pl-xl-10 {
    padding-left: 10px;
  }

  .ma-xl-15 {
    margin: 15px;
  }

  .pa-xl-15 {
    padding: 15px;
  }

  .mv-xl-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mh-xl-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt-xl-15 {
    margin-top: 15px;
  }

  .mr-xl-15 {
    margin-right: 15px;
  }

  .mb-xl-15 {
    margin-bottom: 15px;
  }

  .ml-xl-15 {
    margin-left: 15px;
  }

  .pv-xl-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ph-xl-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pt-xl-15 {
    padding-top: 15px;
  }

  .pr-xl-15 {
    padding-right: 15px;
  }

  .pb-xl-15 {
    padding-bottom: 15px;
  }

  .pl-xl-15 {
    padding-left: 15px;
  }

  .ma-xl-20 {
    margin: 20px;
  }

  .pa-xl-20 {
    padding: 20px;
  }

  .mv-xl-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mh-xl-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-xl-20 {
    margin-top: 20px;
  }

  .mr-xl-20 {
    margin-right: 20px;
  }

  .mb-xl-20 {
    margin-bottom: 20px;
  }

  .ml-xl-20 {
    margin-left: 20px;
  }

  .pv-xl-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ph-xl-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt-xl-20 {
    padding-top: 20px;
  }

  .pr-xl-20 {
    padding-right: 20px;
  }

  .pb-xl-20 {
    padding-bottom: 20px;
  }

  .pl-xl-20 {
    padding-left: 20px;
  }

  .ma-xl-25 {
    margin: 25px;
  }

  .pa-xl-25 {
    padding: 25px;
  }

  .mv-xl-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mh-xl-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mt-xl-25 {
    margin-top: 25px;
  }

  .mr-xl-25 {
    margin-right: 25px;
  }

  .mb-xl-25 {
    margin-bottom: 25px;
  }

  .ml-xl-25 {
    margin-left: 25px;
  }

  .pv-xl-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .ph-xl-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pt-xl-25 {
    padding-top: 25px;
  }

  .pr-xl-25 {
    padding-right: 25px;
  }

  .pb-xl-25 {
    padding-bottom: 25px;
  }

  .pl-xl-25 {
    padding-left: 25px;
  }

  .ma-xl-30 {
    margin: 30px;
  }

  .pa-xl-30 {
    padding: 30px;
  }

  .mv-xl-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mh-xl-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mt-xl-30 {
    margin-top: 30px;
  }

  .mr-xl-30 {
    margin-right: 30px;
  }

  .mb-xl-30 {
    margin-bottom: 30px;
  }

  .ml-xl-30 {
    margin-left: 30px;
  }

  .pv-xl-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ph-xl-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pt-xl-30 {
    padding-top: 30px;
  }

  .pr-xl-30 {
    padding-right: 30px;
  }

  .pb-xl-30 {
    padding-bottom: 30px;
  }

  .pl-xl-30 {
    padding-left: 30px;
  }

  .ma-xl-35 {
    margin: 35px;
  }

  .pa-xl-35 {
    padding: 35px;
  }

  .mv-xl-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mh-xl-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mt-xl-35 {
    margin-top: 35px;
  }

  .mr-xl-35 {
    margin-right: 35px;
  }

  .mb-xl-35 {
    margin-bottom: 35px;
  }

  .ml-xl-35 {
    margin-left: 35px;
  }

  .pv-xl-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ph-xl-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pt-xl-35 {
    padding-top: 35px;
  }

  .pr-xl-35 {
    padding-right: 35px;
  }

  .pb-xl-35 {
    padding-bottom: 35px;
  }

  .pl-xl-35 {
    padding-left: 35px;
  }

  .ma-xl-40 {
    margin: 40px;
  }

  .pa-xl-40 {
    padding: 40px;
  }

  .mv-xl-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mh-xl-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mt-xl-40 {
    margin-top: 40px;
  }

  .mr-xl-40 {
    margin-right: 40px;
  }

  .mb-xl-40 {
    margin-bottom: 40px;
  }

  .ml-xl-40 {
    margin-left: 40px;
  }

  .pv-xl-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ph-xl-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pt-xl-40 {
    padding-top: 40px;
  }

  .pr-xl-40 {
    padding-right: 40px;
  }

  .pb-xl-40 {
    padding-bottom: 40px;
  }

  .pl-xl-40 {
    padding-left: 40px;
  }

  .ma-xl-45 {
    margin: 45px;
  }

  .pa-xl-45 {
    padding: 45px;
  }

  .mv-xl-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mh-xl-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mt-xl-45 {
    margin-top: 45px;
  }

  .mr-xl-45 {
    margin-right: 45px;
  }

  .mb-xl-45 {
    margin-bottom: 45px;
  }

  .ml-xl-45 {
    margin-left: 45px;
  }

  .pv-xl-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .ph-xl-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pt-xl-45 {
    padding-top: 45px;
  }

  .pr-xl-45 {
    padding-right: 45px;
  }

  .pb-xl-45 {
    padding-bottom: 45px;
  }

  .pl-xl-45 {
    padding-left: 45px;
  }

  .ma-xl-50 {
    margin: 50px;
  }

  .pa-xl-50 {
    padding: 50px;
  }

  .mv-xl-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mh-xl-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mt-xl-50 {
    margin-top: 50px;
  }

  .mr-xl-50 {
    margin-right: 50px;
  }

  .mb-xl-50 {
    margin-bottom: 50px;
  }

  .ml-xl-50 {
    margin-left: 50px;
  }

  .pv-xl-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph-xl-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pt-xl-50 {
    padding-top: 50px;
  }

  .pr-xl-50 {
    padding-right: 50px;
  }

  .pb-xl-50 {
    padding-bottom: 50px;
  }

  .pl-xl-50 {
    padding-left: 50px;
  }

  .ma-xl-55 {
    margin: 55px;
  }

  .pa-xl-55 {
    padding: 55px;
  }

  .mv-xl-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mh-xl-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mt-xl-55 {
    margin-top: 55px;
  }

  .mr-xl-55 {
    margin-right: 55px;
  }

  .mb-xl-55 {
    margin-bottom: 55px;
  }

  .ml-xl-55 {
    margin-left: 55px;
  }

  .pv-xl-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .ph-xl-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pt-xl-55 {
    padding-top: 55px;
  }

  .pr-xl-55 {
    padding-right: 55px;
  }

  .pb-xl-55 {
    padding-bottom: 55px;
  }

  .pl-xl-55 {
    padding-left: 55px;
  }

  .ma-xl-60 {
    margin: 60px;
  }

  .pa-xl-60 {
    padding: 60px;
  }

  .mv-xl-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mh-xl-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mt-xl-60 {
    margin-top: 60px;
  }

  .mr-xl-60 {
    margin-right: 60px;
  }

  .mb-xl-60 {
    margin-bottom: 60px;
  }

  .ml-xl-60 {
    margin-left: 60px;
  }

  .pv-xl-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ph-xl-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pt-xl-60 {
    padding-top: 60px;
  }

  .pr-xl-60 {
    padding-right: 60px;
  }

  .pb-xl-60 {
    padding-bottom: 60px;
  }

  .pl-xl-60 {
    padding-left: 60px;
  }

  .ma-xl-65 {
    margin: 65px;
  }

  .pa-xl-65 {
    padding: 65px;
  }

  .mv-xl-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mh-xl-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mt-xl-65 {
    margin-top: 65px;
  }

  .mr-xl-65 {
    margin-right: 65px;
  }

  .mb-xl-65 {
    margin-bottom: 65px;
  }

  .ml-xl-65 {
    margin-left: 65px;
  }

  .pv-xl-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .ph-xl-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pt-xl-65 {
    padding-top: 65px;
  }

  .pr-xl-65 {
    padding-right: 65px;
  }

  .pb-xl-65 {
    padding-bottom: 65px;
  }

  .pl-xl-65 {
    padding-left: 65px;
  }

  .ma-xl-70 {
    margin: 70px;
  }

  .pa-xl-70 {
    padding: 70px;
  }

  .mv-xl-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mh-xl-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mt-xl-70 {
    margin-top: 70px;
  }

  .mr-xl-70 {
    margin-right: 70px;
  }

  .mb-xl-70 {
    margin-bottom: 70px;
  }

  .ml-xl-70 {
    margin-left: 70px;
  }

  .pv-xl-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ph-xl-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pt-xl-70 {
    padding-top: 70px;
  }

  .pr-xl-70 {
    padding-right: 70px;
  }

  .pb-xl-70 {
    padding-bottom: 70px;
  }

  .pl-xl-70 {
    padding-left: 70px;
  }

  .ma-xl-75 {
    margin: 75px;
  }

  .pa-xl-75 {
    padding: 75px;
  }

  .mv-xl-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mh-xl-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mt-xl-75 {
    margin-top: 75px;
  }

  .mr-xl-75 {
    margin-right: 75px;
  }

  .mb-xl-75 {
    margin-bottom: 75px;
  }

  .ml-xl-75 {
    margin-left: 75px;
  }

  .pv-xl-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ph-xl-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pt-xl-75 {
    padding-top: 75px;
  }

  .pr-xl-75 {
    padding-right: 75px;
  }

  .pb-xl-75 {
    padding-bottom: 75px;
  }

  .pl-xl-75 {
    padding-left: 75px;
  }

  .ma-xl-80 {
    margin: 80px;
  }

  .pa-xl-80 {
    padding: 80px;
  }

  .mv-xl-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mh-xl-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mt-xl-80 {
    margin-top: 80px;
  }

  .mr-xl-80 {
    margin-right: 80px;
  }

  .mb-xl-80 {
    margin-bottom: 80px;
  }

  .ml-xl-80 {
    margin-left: 80px;
  }

  .pv-xl-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .ph-xl-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pt-xl-80 {
    padding-top: 80px;
  }

  .pr-xl-80 {
    padding-right: 80px;
  }

  .pb-xl-80 {
    padding-bottom: 80px;
  }

  .pl-xl-80 {
    padding-left: 80px;
  }

  .ma-xl-85 {
    margin: 85px;
  }

  .pa-xl-85 {
    padding: 85px;
  }

  .mv-xl-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mh-xl-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mt-xl-85 {
    margin-top: 85px;
  }

  .mr-xl-85 {
    margin-right: 85px;
  }

  .mb-xl-85 {
    margin-bottom: 85px;
  }

  .ml-xl-85 {
    margin-left: 85px;
  }

  .pv-xl-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .ph-xl-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pt-xl-85 {
    padding-top: 85px;
  }

  .pr-xl-85 {
    padding-right: 85px;
  }

  .pb-xl-85 {
    padding-bottom: 85px;
  }

  .pl-xl-85 {
    padding-left: 85px;
  }

  .ma-xl-90 {
    margin: 90px;
  }

  .pa-xl-90 {
    padding: 90px;
  }

  .mv-xl-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mh-xl-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mt-xl-90 {
    margin-top: 90px;
  }

  .mr-xl-90 {
    margin-right: 90px;
  }

  .mb-xl-90 {
    margin-bottom: 90px;
  }

  .ml-xl-90 {
    margin-left: 90px;
  }

  .pv-xl-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ph-xl-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pt-xl-90 {
    padding-top: 90px;
  }

  .pr-xl-90 {
    padding-right: 90px;
  }

  .pb-xl-90 {
    padding-bottom: 90px;
  }

  .pl-xl-90 {
    padding-left: 90px;
  }

  .ma-xl-95 {
    margin: 95px;
  }

  .pa-xl-95 {
    padding: 95px;
  }

  .mv-xl-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mh-xl-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mt-xl-95 {
    margin-top: 95px;
  }

  .mr-xl-95 {
    margin-right: 95px;
  }

  .mb-xl-95 {
    margin-bottom: 95px;
  }

  .ml-xl-95 {
    margin-left: 95px;
  }

  .pv-xl-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .ph-xl-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pt-xl-95 {
    padding-top: 95px;
  }

  .pr-xl-95 {
    padding-right: 95px;
  }

  .pb-xl-95 {
    padding-bottom: 95px;
  }

  .pl-xl-95 {
    padding-left: 95px;
  }

  .ma-xl-100 {
    margin: 100px;
  }

  .pa-xl-100 {
    padding: 100px;
  }

  .mv-xl-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mh-xl-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mt-xl-100 {
    margin-top: 100px;
  }

  .mr-xl-100 {
    margin-right: 100px;
  }

  .mb-xl-100 {
    margin-bottom: 100px;
  }

  .ml-xl-100 {
    margin-left: 100px;
  }

  .pv-xl-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ph-xl-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pt-xl-100 {
    padding-top: 100px;
  }

  .pr-xl-100 {
    padding-right: 100px;
  }

  .pb-xl-100 {
    padding-bottom: 100px;
  }

  .pl-xl-100 {
    padding-left: 100px;
  }
}

@media (width >= 1400px) {
  .ma-xxl-0 {
    margin: 0;
  }

  .pa-xxl-0 {
    padding: 0;
  }

  .mv-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mh-xxl-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-xxl-0 {
    margin-top: 0;
  }

  .mr-xxl-0 {
    margin-right: 0;
  }

  .mb-xxl-0 {
    margin-bottom: 0;
  }

  .ml-xxl-0 {
    margin-left: 0;
  }

  .pv-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ph-xxl-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .pt-xxl-0 {
    padding-top: 0;
  }

  .pr-xxl-0 {
    padding-right: 0;
  }

  .pb-xxl-0 {
    padding-bottom: 0;
  }

  .pl-xxl-0 {
    padding-left: 0;
  }

  .ma-xxl-5 {
    margin: 5px;
  }

  .pa-xxl-5 {
    padding: 5px;
  }

  .mv-xxl-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mh-xxl-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mt-xxl-5 {
    margin-top: 5px;
  }

  .mr-xxl-5 {
    margin-right: 5px;
  }

  .mb-xxl-5 {
    margin-bottom: 5px;
  }

  .ml-xxl-5 {
    margin-left: 5px;
  }

  .pv-xxl-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ph-xxl-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pt-xxl-5 {
    padding-top: 5px;
  }

  .pr-xxl-5 {
    padding-right: 5px;
  }

  .pb-xxl-5 {
    padding-bottom: 5px;
  }

  .pl-xxl-5 {
    padding-left: 5px;
  }

  .ma-xxl-10 {
    margin: 10px;
  }

  .pa-xxl-10 {
    padding: 10px;
  }

  .mv-xxl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mh-xxl-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt-xxl-10 {
    margin-top: 10px;
  }

  .mr-xxl-10 {
    margin-right: 10px;
  }

  .mb-xxl-10 {
    margin-bottom: 10px;
  }

  .ml-xxl-10 {
    margin-left: 10px;
  }

  .pv-xxl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ph-xxl-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-xxl-10 {
    padding-top: 10px;
  }

  .pr-xxl-10 {
    padding-right: 10px;
  }

  .pb-xxl-10 {
    padding-bottom: 10px;
  }

  .pl-xxl-10 {
    padding-left: 10px;
  }

  .ma-xxl-15 {
    margin: 15px;
  }

  .pa-xxl-15 {
    padding: 15px;
  }

  .mv-xxl-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mh-xxl-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt-xxl-15 {
    margin-top: 15px;
  }

  .mr-xxl-15 {
    margin-right: 15px;
  }

  .mb-xxl-15 {
    margin-bottom: 15px;
  }

  .ml-xxl-15 {
    margin-left: 15px;
  }

  .pv-xxl-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ph-xxl-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pt-xxl-15 {
    padding-top: 15px;
  }

  .pr-xxl-15 {
    padding-right: 15px;
  }

  .pb-xxl-15 {
    padding-bottom: 15px;
  }

  .pl-xxl-15 {
    padding-left: 15px;
  }

  .ma-xxl-20 {
    margin: 20px;
  }

  .pa-xxl-20 {
    padding: 20px;
  }

  .mv-xxl-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mh-xxl-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-xxl-20 {
    margin-top: 20px;
  }

  .mr-xxl-20 {
    margin-right: 20px;
  }

  .mb-xxl-20 {
    margin-bottom: 20px;
  }

  .ml-xxl-20 {
    margin-left: 20px;
  }

  .pv-xxl-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ph-xxl-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt-xxl-20 {
    padding-top: 20px;
  }

  .pr-xxl-20 {
    padding-right: 20px;
  }

  .pb-xxl-20 {
    padding-bottom: 20px;
  }

  .pl-xxl-20 {
    padding-left: 20px;
  }

  .ma-xxl-25 {
    margin: 25px;
  }

  .pa-xxl-25 {
    padding: 25px;
  }

  .mv-xxl-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mh-xxl-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mt-xxl-25 {
    margin-top: 25px;
  }

  .mr-xxl-25 {
    margin-right: 25px;
  }

  .mb-xxl-25 {
    margin-bottom: 25px;
  }

  .ml-xxl-25 {
    margin-left: 25px;
  }

  .pv-xxl-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .ph-xxl-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pt-xxl-25 {
    padding-top: 25px;
  }

  .pr-xxl-25 {
    padding-right: 25px;
  }

  .pb-xxl-25 {
    padding-bottom: 25px;
  }

  .pl-xxl-25 {
    padding-left: 25px;
  }

  .ma-xxl-30 {
    margin: 30px;
  }

  .pa-xxl-30 {
    padding: 30px;
  }

  .mv-xxl-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mh-xxl-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mt-xxl-30 {
    margin-top: 30px;
  }

  .mr-xxl-30 {
    margin-right: 30px;
  }

  .mb-xxl-30 {
    margin-bottom: 30px;
  }

  .ml-xxl-30 {
    margin-left: 30px;
  }

  .pv-xxl-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ph-xxl-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pt-xxl-30 {
    padding-top: 30px;
  }

  .pr-xxl-30 {
    padding-right: 30px;
  }

  .pb-xxl-30 {
    padding-bottom: 30px;
  }

  .pl-xxl-30 {
    padding-left: 30px;
  }

  .ma-xxl-35 {
    margin: 35px;
  }

  .pa-xxl-35 {
    padding: 35px;
  }

  .mv-xxl-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mh-xxl-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mt-xxl-35 {
    margin-top: 35px;
  }

  .mr-xxl-35 {
    margin-right: 35px;
  }

  .mb-xxl-35 {
    margin-bottom: 35px;
  }

  .ml-xxl-35 {
    margin-left: 35px;
  }

  .pv-xxl-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ph-xxl-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pt-xxl-35 {
    padding-top: 35px;
  }

  .pr-xxl-35 {
    padding-right: 35px;
  }

  .pb-xxl-35 {
    padding-bottom: 35px;
  }

  .pl-xxl-35 {
    padding-left: 35px;
  }

  .ma-xxl-40 {
    margin: 40px;
  }

  .pa-xxl-40 {
    padding: 40px;
  }

  .mv-xxl-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mh-xxl-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mt-xxl-40 {
    margin-top: 40px;
  }

  .mr-xxl-40 {
    margin-right: 40px;
  }

  .mb-xxl-40 {
    margin-bottom: 40px;
  }

  .ml-xxl-40 {
    margin-left: 40px;
  }

  .pv-xxl-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ph-xxl-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pt-xxl-40 {
    padding-top: 40px;
  }

  .pr-xxl-40 {
    padding-right: 40px;
  }

  .pb-xxl-40 {
    padding-bottom: 40px;
  }

  .pl-xxl-40 {
    padding-left: 40px;
  }

  .ma-xxl-45 {
    margin: 45px;
  }

  .pa-xxl-45 {
    padding: 45px;
  }

  .mv-xxl-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mh-xxl-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mt-xxl-45 {
    margin-top: 45px;
  }

  .mr-xxl-45 {
    margin-right: 45px;
  }

  .mb-xxl-45 {
    margin-bottom: 45px;
  }

  .ml-xxl-45 {
    margin-left: 45px;
  }

  .pv-xxl-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .ph-xxl-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pt-xxl-45 {
    padding-top: 45px;
  }

  .pr-xxl-45 {
    padding-right: 45px;
  }

  .pb-xxl-45 {
    padding-bottom: 45px;
  }

  .pl-xxl-45 {
    padding-left: 45px;
  }

  .ma-xxl-50 {
    margin: 50px;
  }

  .pa-xxl-50 {
    padding: 50px;
  }

  .mv-xxl-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mh-xxl-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mt-xxl-50 {
    margin-top: 50px;
  }

  .mr-xxl-50 {
    margin-right: 50px;
  }

  .mb-xxl-50 {
    margin-bottom: 50px;
  }

  .ml-xxl-50 {
    margin-left: 50px;
  }

  .pv-xxl-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph-xxl-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pt-xxl-50 {
    padding-top: 50px;
  }

  .pr-xxl-50 {
    padding-right: 50px;
  }

  .pb-xxl-50 {
    padding-bottom: 50px;
  }

  .pl-xxl-50 {
    padding-left: 50px;
  }

  .ma-xxl-55 {
    margin: 55px;
  }

  .pa-xxl-55 {
    padding: 55px;
  }

  .mv-xxl-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mh-xxl-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mt-xxl-55 {
    margin-top: 55px;
  }

  .mr-xxl-55 {
    margin-right: 55px;
  }

  .mb-xxl-55 {
    margin-bottom: 55px;
  }

  .ml-xxl-55 {
    margin-left: 55px;
  }

  .pv-xxl-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .ph-xxl-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pt-xxl-55 {
    padding-top: 55px;
  }

  .pr-xxl-55 {
    padding-right: 55px;
  }

  .pb-xxl-55 {
    padding-bottom: 55px;
  }

  .pl-xxl-55 {
    padding-left: 55px;
  }

  .ma-xxl-60 {
    margin: 60px;
  }

  .pa-xxl-60 {
    padding: 60px;
  }

  .mv-xxl-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mh-xxl-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mt-xxl-60 {
    margin-top: 60px;
  }

  .mr-xxl-60 {
    margin-right: 60px;
  }

  .mb-xxl-60 {
    margin-bottom: 60px;
  }

  .ml-xxl-60 {
    margin-left: 60px;
  }

  .pv-xxl-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ph-xxl-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pt-xxl-60 {
    padding-top: 60px;
  }

  .pr-xxl-60 {
    padding-right: 60px;
  }

  .pb-xxl-60 {
    padding-bottom: 60px;
  }

  .pl-xxl-60 {
    padding-left: 60px;
  }

  .ma-xxl-65 {
    margin: 65px;
  }

  .pa-xxl-65 {
    padding: 65px;
  }

  .mv-xxl-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mh-xxl-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mt-xxl-65 {
    margin-top: 65px;
  }

  .mr-xxl-65 {
    margin-right: 65px;
  }

  .mb-xxl-65 {
    margin-bottom: 65px;
  }

  .ml-xxl-65 {
    margin-left: 65px;
  }

  .pv-xxl-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .ph-xxl-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pt-xxl-65 {
    padding-top: 65px;
  }

  .pr-xxl-65 {
    padding-right: 65px;
  }

  .pb-xxl-65 {
    padding-bottom: 65px;
  }

  .pl-xxl-65 {
    padding-left: 65px;
  }

  .ma-xxl-70 {
    margin: 70px;
  }

  .pa-xxl-70 {
    padding: 70px;
  }

  .mv-xxl-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mh-xxl-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mt-xxl-70 {
    margin-top: 70px;
  }

  .mr-xxl-70 {
    margin-right: 70px;
  }

  .mb-xxl-70 {
    margin-bottom: 70px;
  }

  .ml-xxl-70 {
    margin-left: 70px;
  }

  .pv-xxl-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ph-xxl-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pt-xxl-70 {
    padding-top: 70px;
  }

  .pr-xxl-70 {
    padding-right: 70px;
  }

  .pb-xxl-70 {
    padding-bottom: 70px;
  }

  .pl-xxl-70 {
    padding-left: 70px;
  }

  .ma-xxl-75 {
    margin: 75px;
  }

  .pa-xxl-75 {
    padding: 75px;
  }

  .mv-xxl-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mh-xxl-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mt-xxl-75 {
    margin-top: 75px;
  }

  .mr-xxl-75 {
    margin-right: 75px;
  }

  .mb-xxl-75 {
    margin-bottom: 75px;
  }

  .ml-xxl-75 {
    margin-left: 75px;
  }

  .pv-xxl-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ph-xxl-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pt-xxl-75 {
    padding-top: 75px;
  }

  .pr-xxl-75 {
    padding-right: 75px;
  }

  .pb-xxl-75 {
    padding-bottom: 75px;
  }

  .pl-xxl-75 {
    padding-left: 75px;
  }

  .ma-xxl-80 {
    margin: 80px;
  }

  .pa-xxl-80 {
    padding: 80px;
  }

  .mv-xxl-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mh-xxl-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mt-xxl-80 {
    margin-top: 80px;
  }

  .mr-xxl-80 {
    margin-right: 80px;
  }

  .mb-xxl-80 {
    margin-bottom: 80px;
  }

  .ml-xxl-80 {
    margin-left: 80px;
  }

  .pv-xxl-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .ph-xxl-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pt-xxl-80 {
    padding-top: 80px;
  }

  .pr-xxl-80 {
    padding-right: 80px;
  }

  .pb-xxl-80 {
    padding-bottom: 80px;
  }

  .pl-xxl-80 {
    padding-left: 80px;
  }

  .ma-xxl-85 {
    margin: 85px;
  }

  .pa-xxl-85 {
    padding: 85px;
  }

  .mv-xxl-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mh-xxl-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mt-xxl-85 {
    margin-top: 85px;
  }

  .mr-xxl-85 {
    margin-right: 85px;
  }

  .mb-xxl-85 {
    margin-bottom: 85px;
  }

  .ml-xxl-85 {
    margin-left: 85px;
  }

  .pv-xxl-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .ph-xxl-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pt-xxl-85 {
    padding-top: 85px;
  }

  .pr-xxl-85 {
    padding-right: 85px;
  }

  .pb-xxl-85 {
    padding-bottom: 85px;
  }

  .pl-xxl-85 {
    padding-left: 85px;
  }

  .ma-xxl-90 {
    margin: 90px;
  }

  .pa-xxl-90 {
    padding: 90px;
  }

  .mv-xxl-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mh-xxl-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mt-xxl-90 {
    margin-top: 90px;
  }

  .mr-xxl-90 {
    margin-right: 90px;
  }

  .mb-xxl-90 {
    margin-bottom: 90px;
  }

  .ml-xxl-90 {
    margin-left: 90px;
  }

  .pv-xxl-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ph-xxl-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pt-xxl-90 {
    padding-top: 90px;
  }

  .pr-xxl-90 {
    padding-right: 90px;
  }

  .pb-xxl-90 {
    padding-bottom: 90px;
  }

  .pl-xxl-90 {
    padding-left: 90px;
  }

  .ma-xxl-95 {
    margin: 95px;
  }

  .pa-xxl-95 {
    padding: 95px;
  }

  .mv-xxl-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mh-xxl-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mt-xxl-95 {
    margin-top: 95px;
  }

  .mr-xxl-95 {
    margin-right: 95px;
  }

  .mb-xxl-95 {
    margin-bottom: 95px;
  }

  .ml-xxl-95 {
    margin-left: 95px;
  }

  .pv-xxl-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .ph-xxl-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pt-xxl-95 {
    padding-top: 95px;
  }

  .pr-xxl-95 {
    padding-right: 95px;
  }

  .pb-xxl-95 {
    padding-bottom: 95px;
  }

  .pl-xxl-95 {
    padding-left: 95px;
  }

  .ma-xxl-100 {
    margin: 100px;
  }

  .pa-xxl-100 {
    padding: 100px;
  }

  .mv-xxl-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mh-xxl-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mt-xxl-100 {
    margin-top: 100px;
  }

  .mr-xxl-100 {
    margin-right: 100px;
  }

  .mb-xxl-100 {
    margin-bottom: 100px;
  }

  .ml-xxl-100 {
    margin-left: 100px;
  }

  .pv-xxl-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ph-xxl-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pt-xxl-100 {
    padding-top: 100px;
  }

  .pr-xxl-100 {
    padding-right: 100px;
  }

  .pb-xxl-100 {
    padding-bottom: 100px;
  }

  .pl-xxl-100 {
    padding-left: 100px;
  }
}

body, html {
  width: 100%;
  background-color: #fff;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  overflow-x: hidden;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, label {
  margin-bottom: 0;
  font-weight: 400;
}

figure {
  margin: 0;
}

button {
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  padding: 0;
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol ol, ol ul, ul ol, ul ul {
  padding: 0;
  list-style: none;
}

p {
  margin-bottom: 0;
}

@viewport {
  width: device-width;
  zoom: 1;
}

.renderer-optmimize {
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important;
}

@media (width <= 575.98px) {
  .equal-col-item {
    min-height: 0 !important;
  }
}

body.search-open #header-top .search-wrapper {
  display: block;
}

#header {
  width: 100%;
  z-index: 10;
  background-color: #fff;
  transition: all .2s ease-in-out;
  position: relative;
}

#header .container {
  max-width: 1500px;
}

@media (width >= 768px) {
  #header .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

#header-top {
  padding: 15px 0;
  font-family: intermedium, sans-serif;
  line-height: 1.2em;
}

#header-top .search-wrapper {
  position: relative;
}

#header-top .search-wrapper input {
  width: 100%;
  background-color: #f4f4f4;
  border: 0;
  border-radius: 50px;
  outline: 0;
  padding: 24.5px 60px 20px 21.5px;
}

#header-top .search-wrapper input::placeholder {
  font-family: interlight, sans-serif;
  font-style: italic;
}

#header-top .search-wrapper button {
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

#header-top .search-wrapper button:hover {
  transform: scale(.95);
}

@media (width <= 767.98px) {
  #header-top .search-wrapper {
    margin-top: 10px;
    display: none;
  }

  #header-top .search-wrapper input {
    border-radius: 0;
    padding-left: 10px;
  }

  #header-top .search-wrapper button {
    transform: scale(.9);
  }
}

#header-top .icon-search {
  margin-top: 6px;
  font-size: 2.07692rem;
}

@media (width <= 991.98px) {
  #header-top {
    padding: 10px 0;
  }

  #header-top .menu-text {
    font-size: .923077rem;
  }

  #header-top .menu-text:hover {
    color: #1a25f1;
  }
}

@media (width <= 767.98px) {
  #header-top .logo {
    width: 110px;
    display: block;
  }
}

#header-bottom {
  background-color: #f4f4f4;
  position: relative;
}

#navigation-main {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

#navigation-main .item {
  flex-grow: 1;
}

#navigation-main .link {
  color: #000;
  white-space: nowrap;
  padding: 15px;
  font-family: intermedium, sans-serif;
  font-size: 1.07692rem;
  transition: all .2s ease-in-out;
  display: block;
}

#navigation-main .link:focus, #navigation-main .link:hover, #navigation-main .link.active {
  color: #1a25f1;
}

#navigation-main .link.open:after {
  content: "-";
}

@media (width <= 991.98px) {
  #navigation-main .link {
    padding: 15px 10px;
    font-size: 1rem;
  }
}

@media (width <= 767.98px) {
  #navigation-main {
    border-top: 1px solid #c1c1c1;
  }

  #navigation-main .link {
    border-bottom: 1px solid #c1c1c1;
    font-size: 1.23077rem;
    position: relative;
  }

  #navigation-main .link:after {
    content: "+";
    color: #1a25f1;
    text-align: center;
    height: 100%;
    width: 50px;
    background-color: #e7e7e7;
    border-left: 1px solid #c1c1c1;
    font-family: interlight, sans-serif;
    font-size: 2.30769rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

#navigation-main .navigation-sub .item-sub {
  margin-top: 5px;
}

#navigation-main .navigation-sub .link-sub {
  padding-left: 15px;
  font-family: interlight, sans-serif;
  font-size: 1.15385rem;
  display: block;
  position: relative;
}

#navigation-main .navigation-sub .link-sub:before {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
}

#navigation-main .navigation-sub a {
  transition: all .2s ease-in-out;
}

#navigation-main .navigation-sub a:hover {
  color: #0fdf99;
}

@media (width <= 991.98px) {
  #navigation-main {
    display: none;
  }
}

@media (width >= 768px) {
  #navigation-main {
    display: flex !important;
  }
}

li[data-navsub].focus > .navigation-sub {
  height: auto;
  opacity: 1;
  visibility: visible;
  display: block;
}

li .navigation-sub {
  color: #fff;
  height: 0;
  opacity: 0;
  width: 100%;
  z-index: 99;
  background-color: #000;
  margin-left: 0;
  padding: 10px 15px 40px;
  transition: all .1s ease-in-out;
  display: none;
  position: absolute;
  top: 51px;
  left: 0;
  overflow: hidden;
}

@media (width <= 767.98px) {
  li .navigation-sub {
    height: auto;
    opacity: 1;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    padding: 0 5px 20px;
    position: relative;
    top: auto;
    left: auto;
  }

  li .navigation-sub .title-4 {
    font-size: 1.15385rem;
  }

  body {
    padding-top: 0 !important;
  }
}

@media (width >= 768px) {
  body.header-menu-fixed #header-bottom {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

#footer {
  color: #fff;
  background-color: #000;
}

#footer .footer-navigation {
  transition: all .1s ease-in-out;
  display: block;
}

#footer .footer-navigation:hover {
  color: #fff;
  transform: translateX(5px);
}

.fadeIn, .fadeInDown, .fadeInLeft, .fadeInRight, .fadeInUp {
  visibility: hidden;
}

.irs--round .irs-handle {
  height: 18px !important;
  width: 18px !important;
  border: 0 !important;
  top: 28px !important;
}

.irs--round .irs-bar, .irs--round .irs-handle, .irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  background-color: #1a25f1 !important;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  border-top-color: #1a25f1 !important;
}

.parsley-errors-list {
  color: #a94442;
  margin-top: 5px;
  font-size: 1.07692rem;
}

.selectpicker.parsley-error + .select2 .select2-selection {
  border-color: #a94442 !important;
}

.selectpicker.parsley-error + .select2 .select2-selection .select2-selection__placeholder, .selectpicker.parsley-error + .select2 .select2-selection .select2-search__field {
  color: #a94442 !important;
}

.file-picker--input.parsley-error + .file-picker--label {
  color: #a94442 !important;
  border-color: #a94442 !important;
}

.form-control.parsley-error, .parsley-error.file-picker--label {
  border-color: #a94442 !important;
}

.form-control.parsley-error::placeholder, .parsley-error.file-picker--label::placeholder {
  color: #a94442;
}

.slick-slide {
  outline: none !important;
}

.slick-vertical .slick-slide {
  border: none !important;
}

.slick-dots {
  text-align: center;
  width: 100%;
  z-index: 100;
  margin-top: 15px;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.slick-dots li {
  vertical-align: top;
  margin: 0 4px;
  display: inline-block;
}

.slick-dots button {
  width: 50px;
  height: 1px;
  text-indent: -999em;
  background-color: gray;
  margin-right: 5px;
  transition: all .2s ease-in-out;
  overflow: hidden;
}

@media (width <= 575.98px) {
  .slick-dots button {
    width: 30px;
    height: 3px;
  }
}

.slick-dots .slick-active button, .slick-dots .slick-active button:focus, .slick-dots .slick-active button:hover {
  background-color: #fff;
}

@media (width >= 768px) {
  #slick-products-green-arrows, #slick-products-blue-arrows {
    position: absolute;
    top: -15px;
    right: 15px;
  }
}

@media (width <= 991.98px) {
  .container.except-slick-products {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 20px;
  }
}

@media (width <= 575.98px) {
  .container.except-slick-products {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 5px;
  }
}

.slick-background-blue {
  background-color: #59d2ff;
  background-image: url("carousel-wave-blue.3e181771.png");
  background-position: bottom;
  background-repeat: no-repeat;
}

.slick-background-blue-dark {
  background-color: #1a25f1;
  background-image: url("carousel-wave-blue-dark.8427d74a.png");
  background-position: bottom;
  background-repeat: no-repeat;
}

.slick-background-green {
  background-color: #73fbd3;
  background-image: url("carousel-wave-green.d42fe488.png");
  background-position: bottom;
  background-repeat: no-repeat;
}

#slick-highlight-wrapper {
  position: relative;
}

#slick-highlight-wrapper .slick-item {
  min-height: 590px;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  display: flex !important;
}

#slick-highlight-wrapper .container {
  position: static;
}

#slick-highlight-wrapper .button-regular:hover {
  background-color: #1a25f1;
}

#slick-highlight-wrapper button[class^="arrow"] {
  z-index: 3;
  margin-top: -30px;
  position: absolute;
  top: 50%;
}

@media (width <= 767.98px) {
  #slick-highlight-wrapper button[class^="arrow"] {
    display: none !important;
  }
}

#slick-highlight-wrapper .arrow-prev {
  left: 15px;
}

#slick-highlight-wrapper .arrow-next {
  right: 15px;
}

@media (width <= 767.98px) {
  #slick-highlight-wrapper {
    padding: 0 15px 50px;
  }
}

#slick-highlight-wrapper .slick-slide .text-wrapper {
  opacity: 0;
  transition: all .1s ease-in-out .2s;
  transform: translateX(-100px)scale(1);
}

#slick-highlight-wrapper .slick-slide.animation .text-wrapper {
  opacity: 1;
  transform: translateY(0)scale(1);
}

#slick-product-nav .button {
  border: 5px solid #f4f4f4;
  border-radius: 10px;
  padding: 10px;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 5px #0000;
}

#slick-product-nav .button:hover {
  border: 5px solid #e7e7e7;
  box-shadow: 0 0 5px #0000001a;
}

.brand-item {
  height: 100%;
  background-color: #f4f4f4;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  flex-flow: column wrap;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

.brand-item--image {
  text-align: center;
  width: 100%;
  background-color: #fff;
  padding: 30px 15px;
  display: block;
  overflow: hidden;
}

.brand-item--image img {
  transition: all .1s ease-in-out;
}

.brand-item--title {
  text-align: center;
  border-top: 1px solid #dbdbdb;
  padding: 15px;
  font-family: intermedium, sans-serif;
  font-size: 1.38462rem;
  transition: all .1s ease-in-out;
}

.brand-item:hover .brand-item--image img {
  transform: scale(1.05);
}

.brand-item:hover .brand-item--title {
  background-color: #eaeaea;
}

.filter_letters {
  color: #fff;
  background-color: #1924f1;
  margin-right: 10px;
  padding: 10px;
  font-size: 19px;
}

.item-contact .title-contact {
  text-transform: uppercase;
  padding-left: 25px;
  font-family: interbold, sans-serif;
  font-size: 1.15385rem;
  position: relative;
}

.item-contact .title-contact .material-icons {
  font-size: 1.38462rem;
  position: absolute;
  top: 1px;
  left: 0;
}

.item-contact .list {
  margin-top: 8px;
  padding-left: 25px;
}

.item-contact .list li {
  word-break: break-word;
  padding-left: 11px;
  font-size: 1.15385rem;
  line-height: 1.4em;
  display: block;
  position: relative;
}

.item-contact .list li:not(:last-child) {
  margin-bottom: 7px;
}

.item-contact .list li:before {
  content: "";
  width: 4px;
  height: 1px;
  background-color: #1a25f1;
  position: absolute;
  top: 9px;
  left: 0;
}

@media (width <= 575.98px) {
  .item-contact {
    width: 100%;
    vertical-align: top;
    display: inline-block;
  }
}

.home-category-entry {
  color: #fff;
  height: 700px;
  text-align: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.home-category-entry:after {
  content: "";
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 1;
  background-color: #00000080;
  transition: all .1s ease-in-out;
  position: absolute;
}

.home-category-entry.type-computers {
  background-image: url("category-computers.e6b4654a.png");
}

.home-category-entry.type-components {
  background-image: url("category-components.b51cf128.png");
}

.home-category-entry.type-connectors {
  background-image: url("category-connectors.0a1ffdb1.png");
}

.home-category-entry.type-devices {
  background-image: url("category-devices.f7bb084b.png");
}

.home-category-entry.type-networks {
  background-image: url("category-networks.3d4f0777.png");
}

.home-category-entry.type-consumables {
  background-image: url("category-consumables.18efd265.png");
}

.home-category-entry--menu-wrapper {
  max-height: 100%;
  width: 100%;
  z-index: 2;
  padding: 40px 30px;
  transition: all .1s ease-in-out;
  display: none;
  position: relative;
  overflow-y: auto;
}

.home-category-entry--menu-wrapper--item {
  margin: 15px 0;
  display: block;
}

.home-category-entry--menu-wrapper--link {
  font-family: interbold, sans-serif;
  font-size: 1.69231rem;
  text-decoration: underline;
  transition: all .1s ease-in-out;
}

.home-category-entry--menu-wrapper--link:hover {
  color: #20fc90;
}

@media (width <= 575.98px) {
  .home-category-entry--menu-wrapper--link {
    font-size: 1.38462rem;
  }
}

.home-category-entry:hover .title-wrapper {
  display: none;
}

.home-category-entry:hover .home-category-entry--menu-wrapper {
  display: block;
}

.home-category-entry:hover:after {
  opacity: 1;
}

@media (width <= 1399.98px) {
  .home-category-entry {
    height: 600px;
  }
}

@media (width <= 1199.98px) {
  .home-category-entry {
    height: 500px;
  }
}

.product-item {
  height: 100%;
}

.product-item--link {
  height: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  flex-flow: column;
  justify-content: space-between;
  padding: 15px;
  transition: all .2s ease-in-out;
  display: flex;
  box-shadow: 0 0 10px #0000;
  color: #000 !important;
}

.product-item--link:hover {
  box-shadow: 0 0 10px #0003;
}

.product-item--link:hover .button-regular {
  color: #fff;
  background-color: #000;
}

.product-item--link:hover img {
  transform: scale(1.1);
}

.product-item--image {
  display: block;
  position: relative;
  overflow: hidden;
}

.product-item--image img {
  transition: all 50ms ease-in-out;
}

.product-item--col-bottom {
  text-align: center;
  padding-top: 20px;
}

@media (width >= 992px) {
  .filter-scroll {
    max-height: 130px;
    overflow-y: auto;
  }
}

.label-availability {
  text-transform: uppercase;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: interbold, sans-serif;
  font-size: .846154rem;
  line-height: 1em;
  display: inline-block;
}

.label-availability.available {
  background-color: #20fc90;
}

.label-availability.not-available {
  background-color: #fe7f2e;
}

.faq-item {
  color: #fff;
  text-align: left;
  width: 100%;
  border-radius: 8px;
  line-height: 1.2em;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
  box-shadow: 0 0 20px #0000001a;
}

.faq-item--button {
  color: #1a25f1;
  text-align: left;
  width: 100%;
  padding: 18px 60px 20px 25px;
  font-family: interbold, sans-serif;
  font-size: 1.46154rem;
  line-height: 1.3em;
  display: block;
  position: relative;
}

.faq-item--button .material-icons {
  color: #1a25f1;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 15px;
  right: 15px;
}

.faq-item--content {
  color: #000;
  margin-top: -10px;
  padding: 0 25px 20px;
  transition: all .2s ease-in-out;
}

.faq-item--content a {
  color: #1a25f1;
  text-decoration: underline;
}

.faq-item--content p {
  margin-bottom: 0;
  font-family: intermedium, sans-serif;
  font-size: 1.15385rem;
  line-height: 1.4em;
}

.faq-item.open {
  box-shadow: 0 0 20px #0003;
}

.faq-item.open .faq-item--button .material-icons {
  transform: rotate(-180deg);
}

.faq-item.open--content {
  border-color: #fff;
}

.wysiwyg-wrapper > :first-child {
  margin-top: 0 !important;
}

.wysiwyg-wrapper > * :last-child {
  margin-bottom: 0 !important;
}

.wysiwyg-wrapper h1, .wysiwyg-wrapper .h1, .wysiwyg-wrapper h2, .wysiwyg-wrapper .h2, .wysiwyg-wrapper h3, .wysiwyg-wrapper .h3 {
  color: #1a25f1;
}

.wysiwyg-wrapper h1, .wysiwyg-wrapper .h1, .wysiwyg-wrapper h2, .wysiwyg-wrapper .h2, .wysiwyg-wrapper h3, .wysiwyg-wrapper .h3, .wysiwyg-wrapper h4, .wysiwyg-wrapper .h4, .wysiwyg-wrapper ol, .wysiwyg-wrapper ul {
  margin: 30px 0 35px;
}

.wysiwyg-wrapper .headline {
  font-family: intermedium, sans-serif;
  font-size: 1.69231rem;
}

.wysiwyg-wrapper p {
  margin-bottom: 15px;
}

.wysiwyg-wrapper .highlight-wrapper {
  color: #fff;
  width: 75%;
  background-color: #1a25f1;
  border-radius: 20px;
  margin: 50px auto;
  padding: 30px;
  font-style: italic;
  font-weight: 600;
  display: block;
}

.wysiwyg-wrapper ol, .wysiwyg-wrapper ul {
  counter-reset: ol-counter;
  padding-left: 30px;
}

.wysiwyg-wrapper ol li, .wysiwyg-wrapper ul li {
  margin-bottom: 10px;
  padding-left: 25px;
  display: block;
  position: relative;
}

.wysiwyg-wrapper ul li:before {
  content: "";
  height: 2px;
  width: 8px;
  background-color: #1a25f1;
  position: absolute;
  top: 14px;
  left: 5px;
}

.wysiwyg-wrapper ol li:before {
  content: counter(ol-counter) ".";
  color: #1a25f1;
  counter-increment: ol-counter;
  position: absolute;
  top: 6px;
  left: 0;
}

.wysiwyg-wrapper ol li:last-child {
  margin-bottom: 0;
}

.wysiwyg-wrapper a {
  color: #000;
  text-decoration: underline;
}

.wysiwyg-wrapper .button-wrapper {
  text-align: center;
  margin: 30px 0;
}

.wysiwyg-wrapper .button-wrapper a {
  text-decoration: none;
}

.wysiwyg-wrapper img {
  max-width: 100%;
  height: auto;
}

.wysiwyg-wrapper img.img-left {
  float: left;
  margin: 5px 30px 15px 0;
}

.wysiwyg-wrapper img.img-right {
  float: right;
  margin: 5px 0 15px 30px;
}

.wysiwyg-wrapper img.img-center {
  margin: 50px auto;
  display: block;
}

@media (width <= 767.98px) {
  .wysiwyg-wrapper .highlight-wp {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
  }

  .wysiwyg-wrapper h2, .wysiwyg-wrapper .h2, .wysiwyg-wrapper h3, .wysiwyg-wrapper .h3, .wysiwyg-wrapper ol, .wysiwyg-wrapper ul, .wysiwyg-wrapper .highlight-wrapper, .wysiwyg-wrapper img, .wysiwyg-wrapper .button-wrapper {
    margin: 20px 0 !important;
  }

  .wysiwyg-wrapper img.img-left, .wysiwyg-wrapper img.img-right {
    float: none;
    display: block;
  }
}

/*# sourceMappingURL=index.css.map */
