@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'interbold';
  src: url('fonts/inter-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'interlight';
  src: url('fonts/inter-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'intermedium';
  src: url('fonts/inter-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-light: 'interlight', sans-serif;
$font-regular: 'intermedium', sans-serif;
$font-bold: 'interbold', sans-serif;

$fonts: (
  (title-1, 50, $font-bold, normal, 1.2, title),
  (title-2, 30, $font-bold, normal, 1.2, title),
  (title-3, 28, $font-bold, normal, 1.2, title),
  (title-4, 20, $font-bold, normal, 1.2, title),
  (title-5, 17, $font-bold, normal, 1.2, title),
  (text-1, 20, $font-light, normal, 1.3, text),
  (text-2, 14, $font-bold, normal, 1.2, text),
  (text-3, 14, $font-regular, normal, 1.2, text),
  (text-4, 18, $font-light, normal, 1.3, text),
  (text-5, 15, $font-light, normal, 1.4, text),
  (text-6, 16, $font-light, normal, 1.3, text),

);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-light;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    line-height: $line-height;
    font-style: $font-style;
    font-family: $font-weight;
    @include font-size($font-size);

    @if $type ==title {
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if type == text and size > 15 {
        @include font-size(calc(math.div($font-size,1.2)));
      } 
    }
  }
}