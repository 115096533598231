.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	position: absolute;
	bottom: 20px;
	left: 0;
	margin-top: 15px;
	text-align: center;
	width: 100%;
	z-index: 100;


	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 4px;
	}

	button {
		width: 50px;
		height: 1px;
		margin-right: 5px;
		background-color: color.scale(map.get($colors, 'black'), $lightness: 50%);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
		@include media-breakpoint-down(sm) {
			width: 30px;
			height: 3px;
		}
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'white');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'white');
			}
		}
	}
}


@include media-breakpoint-up(md) {
	#slick-products-green-arrows,
	#slick-products-blue-arrows {
		position: absolute;
		top: -15px;
		right: 15px;
	}
}

.container {
	&.except-slick-products {
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			padding-left: 30px;
			padding-right: 20px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 100%;
			padding-left: 15px;
			padding-right: 5px;
		}
	}
}

$slick-backgrounds : (
	('blue', blue-xlight, 'carousel-wave-blue.png'),
	('blue-dark', blue-xdark, 'carousel-wave-blue-dark.png'),
	('green', green-light, 'carousel-wave-green.png'),
);

@each $label, $color, $wave in $slick-backgrounds {
	.slick-background-#{$label} {
		background-color: map.get($colors,$color);
		background-image: url('assets/images/#{$wave}');
		background-repeat: no-repeat;
		background-position: bottom center;
	}
}


#slick-highlight-wrapper {
	/*padding: 15px 100px;*/
	position: relative;

    .slick-item {
        background-repeat: no-repeat;
        background-position: center;
        min-height: 590px;
        display: flex !important;
        align-items: center;
    }

	.container {
		position: static;
	}

	.button-regular {
		&:hover {
			background-color: map.get($colors, 'blue-xdark');
		}
	}

	button {
		&[class^=arrow] {
			position: absolute;
			top: 50%;
			margin-top: -30px;
			z-index: 3;
			@include media-breakpoint-down(md) {
				display: none !important;
			}
		}
	}

	.arrow {
		&-prev {
			left: 15px;
		}
		&-next {
			right: 15px;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0 15px 50px 15px;
	}


	.slick-slide {
		.text-wrapper {
			opacity: 0;
			transform: translateX(-100px) scale(1);
			transition: all 0.1s ease-in-out 0.2s;
		}

		&.animation {
			.text-wrapper {
				opacity: 1;
				transform: translateY(0) scale(1);
			}
		}
	}
}

#slick-product-nav {
	.button {
		border: 5px solid map.get($colors, 'grey-light');
		box-shadow: 0 0 5px rgba(0,0,0,0);
		border-radius: 10px;
		padding: 10px;
		@include transition(all, 0.2s, ease-in-out);

		&:hover {
			box-shadow: 0 0 5px rgba(0,0,0,.1);
			border: 5px solid darken(map.get($colors, 'grey-light'), 5%);
		}
	}
}
