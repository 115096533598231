.brand-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: map.get($colors, 'grey-light');
  border-radius: 10px;
  border: 1px solid darken(map.get($colors, 'grey-light'), 10%);
  display: flex;
  overflow: hidden;
  flex-flow: column wrap;
  height: 100%;

  &--image {
    background-color: $white;
    display: block;
    padding: 30px 15px;
    text-align: center;
    overflow: hidden;
    width: 100%;

    img {
      @include transition(all, .1s, ease-in-out);
    }
  }

  &--title {
    border-top: 1px solid darken(map.get($colors, 'grey-light'), 10%);
    font-family: $font-regular;
    padding: 15px;
    text-align: center;
    @include font-size(18);
    @include transition(all, .1s, ease-in-out);
  }

  &:hover {
    .brand-item--image {
      img {
        transform: scale(1.05);
      }
    }
    .brand-item--title {
      background-color: darken(map.get($colors, 'grey-light'), 4%);
    }
  }
}

.filter_letters {
    font-size: 19px;
    background-color: #1924f1;
    color: white;
    padding: 10px;
    margin-right: 10px;
}
