body {
	&.search-open {
		#header-top {
			.search-wrapper {
				display: block;
			}
		}
	}
}

#header {
	background-color: map.get($colors, 'white');
	position: relative;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);

	.container {
		max-width: 1500px;
		@include media-breakpoint-up(md) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}

#header-top {
	font-family: $font-regular;
	line-height: 1.2em;
	padding: 15px 0;

	.search-wrapper {
		position: relative;

		input {
			background-color: map.get($colors, 'grey-light');
			border: 0;
			padding: 24.5px 60px 20px 21.5px;
			border-radius: 50px;
			outline: 0;
			width: 100%;

			&::placeholder {
				font-family: $font-light;
				font-style: italic;
			}
		}

		button {
			position: absolute;
			right: 0;
			top: 0;
			@include transition(all, 0.1s, ease-in-out);

			&:hover {
				transform: scale(0.95);
			}
		}

		@include media-breakpoint-down(md) {
			display: none;
			margin-top: 10px;

			input {
				border-radius: 0;
				padding-left: 10px;
			}
			button {
				transform: scale(.9);
			}
		}
	}

	.icon-search {
		@include font-size(27);
		margin-top: 6px;
	}

	@include media-breakpoint-down(lg) {
		padding: 10px 0;

		.menu-text {
			&:hover {
				color: map.get($colors, 'blue-xdark');
			}

			@include font-size(12);
		}

	}

	@include media-breakpoint-down(md) {
		.logo {
			display: block;
			width: 110px;
		}
	}
}

#header-bottom {
	background-color: map.get($colors, 'grey-light');
	position: relative;
}

#navigation-main {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.item {
		flex-grow: 1;
	}

	.link {
		color: map.get($colors, 'black');
		display: block;
		font-family: $font-regular;
		padding: 15px;
		white-space: nowrap;
		@include transition(all, 0.2s, ease-in-out);
		@include font-size(14);

		&:focus,
		&:hover,
		&.active {
			color: map.get($colors, 'blue-xdark');
		}

		&.open {
			&:after {
				content: "-";
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.link {
			padding: 15px 10px;
			@include font-size(13);
		}
	}

	@include media-breakpoint-down(md) {
		border-top: 1px solid darken(map.get($colors, 'grey-light'), 20%);

		.link {
			position: relative;
			border-bottom: 1px solid darken(map.get($colors, 'grey-light'), 20%);

			&:after {
				border-left: 1px solid darken(map.get($colors, 'grey-light'), 20%);
				content:"+";
				background-color:darken(map.get($colors, 'grey-light'), 5%);
				color: map.get($colors, 'blue-xdark');
				text-align: center;
				position: absolute;
				font-family: $font-light;
				height: 100%;
				top: 0;
				right: 0;
				width: 50px;
				@include font-size(30);
			}

			@include font-size(16);
		}
	}

	.navigation-sub {
		.item-sub {
			margin-top: 5px;
		}

		.link-sub {
			display: block;
			font-family: $font-light;
			padding-left: 15px;
			position: relative;

			&:before {
				content: '';
				background-color: $white;
				border-radius: 100%;
				display: block;
				height: 6px;
				left: 0;
				position: absolute;
				top: 8px;
				width: 6px;
			}


			@include font-size(15);

		}

		a {
			@include transition(all, 0.2s, ease-in-out);

			&:hover {
				color: map.get($colors, 'green-dark');
			}
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

@include media-breakpoint-up(md) {
	#navigation-main {
		display: flex !important;
	}
}

li {
	&[data-navsub] {
		&.focus {
			> .navigation-sub {
				display: block;
				height: auto;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.navigation-sub {
		background-color: map.get($colors, 'black');
		color: map.get($colors, 'white');
		display: none;
		margin-left: 0;
		height: 0;
		left: 0;
		opacity: 0;
		overflow: hidden;
		padding: 10px 15px 40px 15px;
		position: absolute;
		top: 51px;
		width: 100%;
		z-index: 99;
		@include transition(all, 0.1s, ease-in-out);

		@include media-breakpoint-down(md) {
			height: auto;
			left: auto;
			margin-left: 0;
			opacity: 1;
			padding: 0 5px 20px;
			margin-top: 0;
			position: relative;
			top: auto;
			width: 100%;

			.title-4 {
				@include font-size(15);
			}
		}
	}
}

body {
	@include media-breakpoint-down(md) {
		padding-top: 0 !important;
	}

	&.header-menu-fixed {
		#header-bottom {
			@include media-breakpoint-up(md) {
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
			}
		}
	}
}
