.irs--round {
  .irs-handle {
    border: 0 !important;
    height: 18px !important;
    top: 28px !important;
    width: 18px !important;
  }

  .irs-bar,
  .irs-handle {    
    background-color: map.get($colors, 'blue-xdark') !important;
  }
  
  .irs-from,
  .irs-to,
  .irs-single {
    background-color: map.get($colors, 'blue-xdark') !important;
    &:before {
      border-top-color: map.get($colors, 'blue-xdark') !important;
    }
  }
}

