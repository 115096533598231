$buttons: (
  (button-regular,
  inline-block,
  14,
  $font-bold,
  none,
  1.2,
  11px 35px,
  center,
  50px,
  $black,
  $white,
  2px solid $black, 
  $white, 
  $black,
  2px solid $black),
  (button-regular-alt,
  inline-block,
  14,
  $font-bold,
  none,
  1.2,
  11px 35px,
  center,
  50px,
  $white,
  $black,
  2px solid $black, 
  $black, 
  $white,
  2px solid $black),
  (button-blue,
  inline-block,
  14,
  $font-bold,
  none,
  1.2,
  11px 35px,
  center,
  50px,
  $white,
  map.get($colors, 'blue-xdark'),
  2px solid map.get($colors, 'blue-xdark'), 
  map.get($colors, 'blue-xdark'),
  $white, 
  2px solid map.get($colors, 'blue-xdark')),
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    @include font-size($font-size);
    @include transition(all, 0.2s, ease-in-out);

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;
    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 30px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);

    &--text {
      position: relative;
      top: 3px;
      text-transform: uppercase;
      @include font-size(9);
    }


  
    span {
      background: $black;
      display: block;
      height: 3px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }
  
    span:nth-child(1) {
      top: 0;
    }
  
    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }
  
    span:nth-child(4) {
      top: 18px;
    }  
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;
  
    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }
    
    &:hover,
    &:focus {}  
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  
    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}